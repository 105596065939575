import {
  BANNERS_CONDITIONS,
  CHECKLIST_TARGETING,
  PINS,
  selectFeature,
} from 'next/entities/features';
import {
  selectProfileAttributeOptions,
  selectFlowTargetingOptions,
  selectEventTargetingOptions,
  selectSegmentTargetingOptions,
  selectChecklistTargetingOptions,
  selectGroupAttributeOptions,
  selectPinTargetingOptions,
  selectMobileFlowTargetingOptions,
  selectBannerTargetingOptions,
} from 'next/lib/selectors-options';

export const selectConditionsOptions = state => {
  return {
    hasChecklistTargeting: selectFeature(state, CHECKLIST_TARGETING),
    hasPinTargeting: selectFeature(state, PINS),
    hasBannerTargeting: selectFeature(state, BANNERS_CONDITIONS),
    checklistOptions: selectChecklistTargetingOptions(state),
    eventOptions: selectEventTargetingOptions(state),
    flowOptions: selectFlowTargetingOptions(state),
    mobileFlowOptions: selectMobileFlowTargetingOptions(state),
    groupOptions: selectGroupAttributeOptions(state),
    propertyOptions: selectProfileAttributeOptions(state),
    segmentOptions: selectSegmentTargetingOptions(state),
    pinOptions: selectPinTargetingOptions(state),
    bannerOptions: selectBannerTargetingOptions(state),
  };
};
