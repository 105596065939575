/* global CANNY_APP_ID */
import React from 'react';
import { connect } from 'react-redux';
import { PopoverHelpMenu } from '@studio/legacy-navigation';

import PropTypes from 'prop-types';
import { selectAccount } from 'next/entities/account';
import { selectUser } from 'next/entities/user';
import useLaunchpad from 'next/hooks/use-launchpad';

const HelpMenu = ({ user = {}, ...unhandledProps }) => {
  const launchpadData = useLaunchpad(user.id);

  return (
    <PopoverHelpMenu
      user={user}
      launchpadData={launchpadData}
      {...unhandledProps}
    />
  );
};

HelpMenu.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    fullname: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  account: selectAccount(state),
  user: selectUser(state),
  cannyAppId: CANNY_APP_ID,
});

export default connect(mapStateToProps)(HelpMenu);
