import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  AccountMenu as NavAccountMenu,
  AccountMenuItem,
} from '@studio/legacy-navigation';
import { ExternalLink } from '@studio/legacy-components';

import { Link } from 'react-router-dom';
import { selectAccount } from 'next/entities/account';
import { selectAccounts } from 'next/entities/accounts';
import { logout, selectUser } from 'next/entities/user';
import TrialMeterByDays from './TrialMeterByDays';

const AccountMenu = ({ account, accounts, user, onLogout }) => {
  return (
    <>
      <TrialMeterByDays />
      <NavAccountMenu
        account={account}
        accounts={accounts}
        user={user}
        onLogout={onLogout}
        Link={Link}
      >
        <AccountMenuItem
          label="Settings"
          path="/settings/account"
          Link={Link}
        />
        <AccountMenuItem
          label="Integrations"
          path="/integrations"
          Link={Link}
        />
        <AccountMenuItem label="Diagnostics" path="/diagnostics" Link={Link} />
        <AccountMenuItem
          label="Appcues status"
          Link={ExternalLink}
          baseUrl="https://status.appcues.com"
          icon={false}
          external
        />
      </NavAccountMenu>
    </>
  );
};

const mapStateToProps = state => ({
  account: selectAccount(state),
  accounts: selectAccounts(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  onLogout: logout,
};

AccountMenu.propTypes = {
  account: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
  accounts: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string })),
  user: PropTypes.shape({
    email: PropTypes.string,
    fullname: PropTypes.string,
  }),
  onLogout: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
