import { call, put, getContext, select } from 'redux-saga/effects';
import { IMAGE_SERVICE_LAMBDA } from 'next/entities/features';
import { takeLeadingByProp } from 'effects/takeLeadingByProp';
import { patterns, replace } from 'actions/account/images';
import { reportError } from 'helpers/error-reporting';
import { selectAccountFeature } from 'reducers/account/features';

/**
 * Loads the specified image from the image-service-client in context
 * and stores in Redux state
 *
 * @param {boolean} forceRegenerate
 * @param {string} flowId
 * @param {string} stepGroupId
 * @param {string} stepChildId
 * @param {string} contentType
 * @param {string} versionId
 * @param {string} size
 * @param {string} localeId
 */

export function* fetchStepChildImage({
  payload: {
    forceRegenerate,
    flowId,
    stepGroupId,
    stepChildId,
    contentType,
    versionId = 'latest',
    size = 'medium',
    localeId,
  },
}) {
  try {
    const client = yield getContext('image');
    const clientLambda = yield getContext('imageLambda');

    const isImageServiceLambdaEnabled = yield select(
      selectAccountFeature,
      IMAGE_SERVICE_LAMBDA
    );

    let image;

    if (isImageServiceLambdaEnabled) {
      image = yield call(clientLambda.get, {
        flowId,
        stepGroupId,
        stepChildId,
        localeId,
      });
    } else {
      if (forceRegenerate) {
        try {
          yield call(client.purge, {
            flowId,
            stepGroupId,
            stepChildId,
            versionId,
            contentType,
            size,
            localeId,
          });
        } catch (error) {
          // allow a stale image to be delivered if the purge request fails
          yield call(reportError, error);
        }
      }

      image = yield call(client.getPresigned, {
        path: yield call(client.presign, {
          flowId,
          stepGroupId,
          stepChildId,
          versionId,
          contentType,
          size,
          localeId,
        }),
      });
    }

    yield put(
      replace({
        id: `${stepChildId}+${versionId}+${size}${
          localeId ? `+${localeId}` : ''
        }`,
        url: URL.createObjectURL(image),
      })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

export default function* saga() {
  yield takeLeadingByProp(
    ({ payload: { stepChildId, versionId, size, localeId } }) =>
      `${stepChildId}+${versionId}+${size}${localeId ? `+${localeId}` : ''}`,
    patterns.callApi,
    fetchStepChildImage
  );
}
