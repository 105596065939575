import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { CPage, CButton } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { history } from 'next/lib/history';
import {
  CONFIGURATION_PAGE,
  INTEGRATION_LISTING_PAGE_REDESIGN,
  MOBILE_FLOWS,
  SETTINGS_NAV_2024,
} from 'next/entities/features';

import { selectAccountFeature } from 'reducers/account/features';

import IndexPage from 'components/Common/IndexPage';
import Installation from 'components/Installation';
import NextInstallation from 'components/Installation/NextInstallation';
import Subscription from 'components/Subscription';
import AccountPage from 'components/AccountPage';
import Billing from 'components/SubscriptionBilling';
import IntegrationsPage from 'components/IntegrationsPage';
import PropertiesAndEvents from 'components/Settings/PropertiesAndEvents';
import Localization from 'components/Settings/Localization';
import ApiKeys from 'components/Settings/keys/ApiKeys';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';
import { FLOW_LOCALIZATION } from 'constants/features';
import { logoutUserFromChromeExtension } from 'actions/chromeExtension';
import { TabsList, TabsRoot, TabsTrigger } from 'components/Common/Tabs';
import { Events } from './Events';
import { Properties } from './Properties';

export function Settings({
  hasFlowLocalization,
  hasMobileFlows,
  hasNewNavigation,
  hasIntegrationPageRedesign,
  hasConfigurationPage,
  logoutUserFromChromeExtension: logoutFromCrx,
  location: { pathname },
}) {
  const handleClickLogout = () => {
    logoutFromCrx();
    authClient.logout();
  };

  const handleTabChange = useCallback(route => {
    history.push(route);
  }, []);

  return (
    <IndexPage
      title="Settings"
      subHeaderActions={
        !hasNewNavigation && (
          <CButton onClick={handleClickLogout}>
            <Icon icon="sign-out-alt" />
            {'\u00A0'} Sign out
          </CButton>
        )
      }
    >
      {!hasNewNavigation && (
        <TabsRoot
          align="left"
          defaultValue={pathname}
          size="regular"
          onValueChange={handleTabChange}
        >
          <TabsList aria-label="tabs default">
            <TabsTrigger value="/settings/account" id="account">
              Account
            </TabsTrigger>
            <TabsTrigger value="/settings/installation" id="installation">
              {hasMobileFlows ? 'Apps & Installation' : 'Installation'}
            </TabsTrigger>
            {!hasIntegrationPageRedesign && (
              <TabsTrigger value="/settings/integrations" id="integrations">
                Integrations
              </TabsTrigger>
            )}
            {hasConfigurationPage && (
              <TabsTrigger value="/settings/identifier" id="identifier">
                Accounts & Users
              </TabsTrigger>
            )}
            <TabsTrigger value="/settings/events" id="events">
              Events and properties
            </TabsTrigger>
            <TabsTrigger value="/settings/team" id="team">
              Team
            </TabsTrigger>
            <TabsTrigger value="/settings/subscription" id="subscription">
              Subscription
            </TabsTrigger>
            {hasFlowLocalization && (
              <TabsTrigger value="/settings/localization" id="localization">
                Localization
              </TabsTrigger>
            )}
            <TabsTrigger value="/settings/keys" id="keys">
              API Keys
            </TabsTrigger>
          </TabsList>
        </TabsRoot>
      )}

      <CPage.Container>
        <Switch>
          <Route path="/settings/account" component={AccountPage} />
          <Route
            path="/settings/installation"
            component={hasMobileFlows ? NextInstallation : Installation}
          />
          {hasIntegrationPageRedesign ? (
            <Redirect
              path="/settings/integrations"
              to="/integrations/connections"
            />
          ) : (
            <Route path="/settings/integrations" component={IntegrationsPage} />
          )}

          {hasNewNavigation && (
            <Route path="/settings/events" component={Events} />
          )}
          {hasNewNavigation && (
            <Route path="/settings/properties" component={Properties} />
          )}
          {!hasNewNavigation && (
            <Route path="/settings/events" component={PropertiesAndEvents} />
          )}
          <Route path="/settings/subscription" component={Subscription} exact />
          <Route path="/settings/billing" component={Billing} exact />

          {hasFlowLocalization && (
            <Route path="/settings/localization" component={Localization} />
          )}
          <Route path="/settings/keys" component={ApiKeys} />
          <Redirect from="/settings/install" to="/settings/installation" />
          <Redirect exact from="/settings" to="/settings/account" />
        </Switch>
      </CPage.Container>
    </IndexPage>
  );
}

Settings.propTypes = {
  hasFlowLocalization: PropTypes.bool,
  hasMobileFlows: PropTypes.bool,
  hasNewNavigation: PropTypes.bool,
  hasIntegrationPageRedesign: PropTypes.bool,
  logoutUserFromChromeExtension: PropTypes.func,
};

const mapStateToProps = state => ({
  hasFlowLocalization: selectAccountFeature(state, FLOW_LOCALIZATION),
  hasMobileFlows: selectAccountFeature(state, MOBILE_FLOWS),
  hasNewNavigation: selectAccountFeature(state, SETTINGS_NAV_2024),
  hasConfigurationPage: selectAccountFeature(state, CONFIGURATION_PAGE),
  hasIntegrationPageRedesign: selectAccountFeature(
    state,
    INTEGRATION_LISTING_PAGE_REDESIGN
  ),
});

const mapDispatchToProps = {
  logoutUserFromChromeExtension,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
