import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CPanel, Text } from '@appcues/component-library';
import { Link } from '@appcues/sonar';
import { CONFIGURATION_PAGE } from 'next/entities/features';
import { getPropertyDisplayName } from 'utils';
import Loader from 'components/Common/Loader';
import Table from 'components/Common/UI/Table';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import { updateAccountMeta } from 'actions/account/meta';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectAccountFeature } from 'reducers/account/features';
import UserSampleTableUserCell from './UserSampleTableUserCell';

const LinkToAll = styled(Link)`
  margin-top: var(--spacing-x-large);
  margin-left: -10px;
`;

export function UserSampleTable({
  title,
  timestampHeader = 'Timestamp',
  emptyStateText = 'No users seen yet',
  onSave,
  userSample,
  userNameField,
  userProfileIdentifier,
  hasConfigurationPage,
  linkToAllUsers = '',
}) {
  const handleProfileAttributeColumnChange = field => {
    onSave({ userNameField: field.value });
  };

  const userSampleRows =
    userSample.meta.synced && !userSample.meta.errored ? userSample.data : [];
  const loaded = userSample.meta.synced;
  const usersExist =
    userSample.meta.synced &&
    !userSample.meta.errored &&
    userSample.data.length > 0;

  const columns = [
    {
      Header: () => {
        return (
          <SelectProfileAttribute
            value={userNameField}
            onChange={handleProfileAttributeColumnChange}
          />
        );
      },
      accessor: 'user_id',
      Cell: ({ value }) => {
        return (
          <UserSampleTableUserCell
            userId={value}
            profileAttributeColumn={userNameField}
            renderAsLink={!hasConfigurationPage}
          />
        );
      },
    },
    {
      Header: timestampHeader,
      accessor: 'timestamp',
      sortable: true,
      Cell: ({ value }) =>
        value ? moment.utc(value).local().format('lll') : '-',
    },
  ];

  // only show the user identifier column when the CONFIGURATION_PAGE feature flag is enabled
  if (hasConfigurationPage) {
    columns.unshift({
      Header: getPropertyDisplayName(userProfileIdentifier),
      id: 'identifier',
      accessor: 'user_id',
      Cell: ({ value }) => {
        return (
          <UserSampleTableUserCell
            userId={value}
            profileAttributeColumn={userProfileIdentifier}
            renderAsLink
          />
        );
      },
    });
  }

  return (
    <CPanel>
      {title}
      {loaded && usersExist && (
        <>
          <Table
            panel
            defaultSortColumn="timestamp"
            containerStyles={{ marginTop: 20 }}
            columns={columns}
            data={userSampleRows}
          />
          {linkToAllUsers ? (
            <LinkToAll href={linkToAllUsers}>See all users</LinkToAll>
          ) : null}
        </>
      )}
      {loaded && !usersExist && (
        <Text type="tertiary" marginY={32}>
          {emptyStateText}
        </Text>
      )}
      {!loaded && <Loader margin="2rem" />}
    </CPanel>
  );
}

UserSampleTable.propTypes = {
  userSample: PropTypes.shape({
    meta: PropTypes.shape({
      fetching: PropTypes.bool,
      synced: PropTypes.bool,
      errored: PropTypes.bool,
    }),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        user_id: PropTypes.string,
        timestamp: PropTypes.string,
      })
    ),
  }),
  title: PropTypes.element,
  timestampHeader: PropTypes.string,
  emptyStateText: PropTypes.string,
  linkToAllUsers: PropTypes.string,
  onSave: PropTypes.func,
};

function mapStateToProps(state) {
  const accountMeta = selectAccountMeta(state);
  const { userNameField, userProfileIdentifier } = accountMeta;

  return {
    userNameField,
    userProfileIdentifier: userProfileIdentifier || 'user_id',
    hasConfigurationPage: selectAccountFeature(state, CONFIGURATION_PAGE),
  };
}

const mapDispatchToProps = {
  onSave: updateAccountMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSampleTable);
