import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import * as Sentry from '@sentry/react';
import { NextApplication } from 'next/main';
import { history } from 'next/lib/history';
import Passthrough from 'next/pages/Passthrough';

import theme from 'styles/theme';
import configureStore from 'store/configureStore';

import App from 'components/App';
import ScrollToTop from 'components/ScrollToTop';
import GlobalStyle from 'components/GlobalStyle';

const Appcues = () => (
  <Router history={history}>
    <NextApplication>
      {({ features }) => {
        const store = configureStore({ features });

        return (
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ScrollToTop />

              <Passthrough features={features}>
                <App />
              </Passthrough>
            </ThemeProvider>
          </Provider>
        );
      }}
    </NextApplication>
  </Router>
);

const AppWithProfiler = Sentry.withProfiler(Appcues);
ReactDOM.render(<AppWithProfiler />, document.querySelector('#root'));
