import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { local } from 'next/lib/storage';
import { CONFIGURATION_PAGE } from 'next/entities/features';
import useQueryString from 'next/hooks/use-query-string';
import { selectAccountFeature } from 'reducers/account/features';
import { selectAccountId, selectAccountMeta } from 'reducers/account/meta';
import QueryResults from 'components/Common/QueryResults';
import List from 'components/Audience/UserList/List';
import { USER_LIST_COLUMNS_KEY } from './constants';

const PAGE_SIZE = 25;

const DEFAULT_ATTRIBUTES = ['userId', 'last_seen'];

const ListProvider = ({
  accountId,
  userProfileIdentifier,
  hasConfigurationPage,
}) => {
  const userListColumnKey = `${accountId}:${USER_LIST_COLUMNS_KEY}`;
  const [attributesNames, setAttributesNames] = useState(
    () => DEFAULT_ATTRIBUTES
  );

  /*
    This explains the branching logic for setting attributesNames and soon will be moved to https://www.notion.so/appcues/Persisted-columns-attributes-logic-ecbd5b97265e49dbb45288ed467f86a1?pvs=4:
    1. persistedAttributes TRUE
      - If CONFIGURATION_PAGE = TRUE:
        - Add userProfileIdentifier at start and filter to prevent duplicates
        - Add lastSeenAt to the end and filter to prevent duplicates
      - If CONFIGURATION_PAGE = FALSE
        - add userId at start and filter to prevent duplicates
        - Add lastSeenAt to the end and filter to prevent duplicates
    2. persistedAttributes FALSE
      - If CONFIGURATION_PAGE = TRUE:
        - Use DEFAULT_COLUMNS and add userProfileIdentifier at start and filter to prevent duplicates
      - If CONFIGURATION_PAGE = FALSE
        - Use DEFAULT_COLUMNS
  */
  useEffect(() => {
    if (!accountId) return;

    setAttributesNames(() => {
      const persistedAttributes = JSON.parse(
        localStorage.getItem(userListColumnKey)
      );

      if (persistedAttributes) {
        const identifier = hasConfigurationPage
          ? userProfileIdentifier
          : 'userId';

        const filteredAttributes = persistedAttributes.filter(
          attribute =>
            attribute !== userProfileIdentifier && attribute !== 'last_seen'
        );

        return [identifier, ...filteredAttributes, 'last_seen'];
      }

      const filteredAttributes = DEFAULT_ATTRIBUTES.filter(
        attribute => attribute !== userProfileIdentifier
      );

      return [userProfileIdentifier, ...filteredAttributes];
    });
  }, [accountId]);

  const [endTime] = useQueryString('end', Date.now(), {
    parse: Number.parseInt,
    validate: Number.isInteger,
  });
  const [sortColumn, setColumn] = useQueryString('col', 'last_seen');
  const [sortDirection, setDirection] = useQueryString('dir', 'desc', {
    validate: val => ['asc', 'desc'].includes(val),
  });
  const [currentPage, setCurrentPage] = useQueryString('page', 0, {
    parse: Number.parseInt,
    validate: Number.isInteger,
  });
  const [searchTerm, setSearchTerm] = useQueryString('search', '');

  const handleColumnsChange = selectedReordered => {
    const identifier = hasConfigurationPage ? userProfileIdentifier : 'userId';
    const filteredAttributes = selectedReordered.filter(
      attribute =>
        attribute !== userProfileIdentifier && attribute !== 'last_seen'
    );
    setAttributesNames([identifier, ...filteredAttributes, 'last_seen']);

    local.set(userListColumnKey, selectedReordered);
  };

  const handleChangeSort = column => {
    if (column === sortColumn) {
      setDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      setCurrentPage(0);
    } else {
      setColumn(column);
      setDirection('desc');
      setCurrentPage(0);
    }
  };

  const handleChangeCurrentPage = page => {
    setCurrentPage(page);
  };

  const handleChangeSearchTerm = query => {
    setCurrentPage(0);
    setSearchTerm(query);
  };

  const columns = [
    'user_id',
    // We should always required a user profile attribute (p:attribute) so the analytics api
    // will group by user id which will prevent weird behaviors, such as duplicating
    // user in the list, to happen.
    'p:userId',
    ...attributesNames.map(name => `p:${name}`),
    'last_seen',
  ];

  if (hasConfigurationPage && userProfileIdentifier) {
    columns.unshift(`p:${userProfileIdentifier}`);
  }

  const conditions = [];

  if (searchTerm) {
    const searchableColumns = attributesNames.map(name => `p:${name}`);

    searchableColumns.push('last_seen', 'user_id');

    conditions.push([
      'or',
      ...searchableColumns.map(columnName => [
        columnName,
        'ilike',
        `%${searchTerm.trim()}%`,
      ]),
    ]);
  }

  return (
    <QueryResults
      query={{
        metrics: ['user_profiles'],
        start_time: 1,
        end_time: endTime,
      }}
    >
      {([{ user_profiles: totalUsers }] = [{}]) => (
        <QueryResults
          query={{
            metrics: ['user_profiles'],
            start_time: 1,
            end_time: endTime,
            conditions,
          }}
        >
          {([{ user_profiles: matchingUsers }] = [{}]) => (
            <QueryResults
              query={{
                columns,
                order_by: [[sortColumn, sortDirection, 'nulls_last']],
                start_time: 1,
                end_time: endTime,
                offset: currentPage * PAGE_SIZE,
                limit: PAGE_SIZE,
                conditions,
              }}
            >
              {results => (
                <List
                  users={results}
                  attributesNames={attributesNames}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  pageSize={PAGE_SIZE}
                  currentPage={currentPage}
                  totalUsers={totalUsers}
                  matchingUsers={matchingUsers}
                  searchTerm={searchTerm}
                  onChangeSort={handleChangeSort}
                  onChangePage={handleChangeCurrentPage}
                  onChangeSearch={handleChangeSearchTerm}
                  onChangeColumns={handleColumnsChange}
                  userProfileIdentifier={userProfileIdentifier}
                  hasConfigurationPage={hasConfigurationPage}
                />
              )}
            </QueryResults>
          )}
        </QueryResults>
      )}
    </QueryResults>
  );
};

ListProvider.propTypes = {
  accountId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  accountId: selectAccountId(state),
  userProfileIdentifier:
    selectAccountMeta(state)?.userProfileIdentifier || 'userId',
  hasConfigurationPage: selectAccountFeature(state, CONFIGURATION_PAGE),
});

export default connect(mapStateToProps)(ListProvider);
