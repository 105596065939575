import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import RecentUsers from './RecentUsers';

export default function RecentUsersContainer({
  endTime,
  startTime,
  segmentId,
  limit,
  id,
  type,
  updated,
}) {
  const [sortColumn, setColumn] = useState('timestamp');

  const [sortDirection, setDirection] = useState('desc');

  const handleRecentUsersChangeSort = useCallback(
    column => {
      if (column === sortColumn) {
        setDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setColumn(column);
        setDirection('desc');
      }
    },
    [sortColumn, sortDirection]
  );

  return (
    <RecentUsers
      experienceType={type}
      onSortChange={handleRecentUsersChangeSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      experienceId={id}
      endTime={endTime}
      startTime={startTime}
      segmentId={segmentId}
      limit={limit}
      updated={updated}
    />
  );
}

RecentUsersContainer.propTypes = {
  endTime: PropTypes.number,
  startTime: PropTypes.number,
  segmentId: PropTypes.string,
  limit: PropTypes.number,
  id: PropTypes.string,
  type: PropTypes.string,
  updated: PropTypes.number,
};
