import React from 'react';
import PropTypes from 'prop-types';
import {
  H1,
  H5,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from '@studio/legacy-components';
import {
  CardHeader,
  MetricCardContainer,
  CardBody,
  CardCaption,
} from './styled';

const MetricCard = ({
  title,
  metric,
  tooltip = null,
  caption = null,
  loading = false,
}) => {
  return (
    <MetricCardContainer>
      <CardBody>
        {loading ? (
          <>
            <Skeleton width="30%" height={24} />
            <Skeleton width="60%" height={48} />
            <Skeleton width="30%" height={20} />
          </>
        ) : (
          <>
            <CardHeader>
              <H5>{title}</H5>
              {tooltip && (
                <Tooltip placement="top" label={tooltip} size="m" wrapped>
                  <Icon icon="info-circle" />
                </Tooltip>
              )}
            </CardHeader>

            <H1>{metric}</H1>

            <CardCaption>{caption && <Text>{caption}</Text>}</CardCaption>
          </>
        )}
      </CardBody>
    </MetricCardContainer>
  );
};

MetricCard.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  metric: PropTypes.string,
  caption: PropTypes.node,
  loading: PropTypes.bool,
};

export default MetricCard;
