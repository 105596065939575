import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from '@studio/legacy-components';
import {
  FlowMetricCard,
  FlowMetricGoalPopover,
  FlowMetricTooltip,
} from 'next/components/FlowMetricCard';
import { fetchGoalsReachedFromFlowStarted } from 'actions/analytics';
import { fetchFlowRule } from 'actions/account/rules';
import { selectGoalsReachedFromFlowStarted } from 'reducers/analytics';
import { selectAccountId } from 'reducers/account/meta';
import { selectAccountRule, selectGoalForRule } from 'reducers/account/rules';
import { selectAccountGoal } from 'reducers/account/goals';
import { asPromised } from 'utils/as-promised';

const GoalMetricCard = ({
  accountId,
  flowId,
  results,
  goalConversionWindowDays,
  goal,
  fetchGoalsData,
  fetchRule,
}) => {
  useEffect(() => {
    const fetchData = async () => {
      // We need to ensure that the rules are always updated in the Redux store when the goal changes.
      // It will garantee that redardless of where the rule was changed, the goal will be consistently updated in the store.
      await fetchRule(flowId);

      if (accountId && goal.id && flowId) {
        fetchGoalsData({
          goalId: goal.id,
          accountId,
          flowId,
          conversionWindow: goalConversionWindowDays,
        });
      }
    };

    fetchData();
  }, [accountId, goal.id, flowId, goalConversionWindowDays]);

  if (!goal?.id) {
    return (
      <FlowMetricCard
        title="Goal"
        metric="-"
        description="No goal set"
        additionalInfo={<FlowMetricGoalPopover flowId={flowId} />}
      />
    );
  }

  const metrics =
    results?.data?.find(({ flow_version: v }) => v === 'all-time') || {};

  const reached = metrics?.goal_reached ?? 0;
  const conversion = metrics?.conversion_rate ?? 0;

  return (
    <FlowMetricCard
      loading={!results.data}
      title="Goal"
      metric={`${Number.parseInt(100 * conversion, 10)}%`}
      description={
        <>
          {reached} unique users achieved{' '}
          <Link title="View Goal details" href={`/goals/${goal?.id}`}>
            {goal?.name}
          </Link>
        </>
      }
      additionalInfo={
        <FlowMetricTooltip>
          Users who met the goal within {goalConversionWindowDays} days after
          seeing the flow
        </FlowMetricTooltip>
      }
    />
  );
};

FlowMetricCard.propTypes = {
  results: PropTypes.array,
  flowId: PropTypes.string,
  conversionWindow: PropTypes.number,
  goal: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

const mapDispatchToProps = dispatch => ({
  fetchGoalsData: params => dispatch(fetchGoalsReachedFromFlowStarted(params)),
  fetchRule: id => asPromised(dispatch, fetchFlowRule(id)),
});

const mapStateToProps = (state, { flowId }) => {
  const goalId = selectGoalForRule(state, flowId);
  const goal = selectAccountGoal(state, goalId) || {};
  const rule = selectAccountRule(state, flowId);

  return {
    results: selectGoalsReachedFromFlowStarted(state),
    accountId: selectAccountId(state),
    goalConversionWindowDays: rule?.goalConversionWindowDays,
    goal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalMetricCard);
