import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { PageBody, PageActions } from '@studio/legacy-components';
import { UserIdUploadModal } from '@studio/settings-panels';
import { history } from 'next/lib/history';
import { S5_SEGMENTS_LIST } from 'next/entities/features';
import useTitle from 'next/hooks/use-title';
import { selectAccountFeature } from 'reducers/account/features';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import IndexPage from 'components/Common/IndexPage';
import { TabsList, TabsRoot, TabsTrigger } from 'components/Common/Tabs';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import UserList from 'components/Audience/UserList';
import SegmentList from 'components/Audience/SegmentList';
import { uploadSegmentUserIds } from 'actions/account/segments';
import { asPromised } from 'utils/as-promised';
import { ButtonLink } from './styled';

export const Audiences = ({
  canCreateSegments,
  match: { path },
  location: { pathname },
  hasS5SegmentsList,
  hasUserIdUpload,
  onUploadSegmentUserIds,
}) => {
  useTitle('Segments | Appcues');

  const handleTabChange = useCallback(route => {
    history.push(route);
  }, []);

  return (
    <IndexPage
      title="Audiences"
      subHeaderActions={
        canCreateSegments ? (
          <Route
            path={`${path}/segments`}
            render={() => (
              <PageActions>
                <ButtonLink to="/segments/new/edit" kind="primary">
                  Create a Segment
                </ButtonLink>
                {hasUserIdUpload && (
                  <UserIdUploadModal
                    onUploadSegmentUserIds={onUploadSegmentUserIds}
                  />
                )}
              </PageActions>
            )}
          />
        ) : null
      }
    >
      <TabsRoot
        align="left"
        defaultValue={pathname}
        size="regular"
        onValueChange={handleTabChange}
      >
        <TabsList aria-label="tabs default">
          <TabsTrigger value={`${path}/segments`} id="segments">
            Segments
          </TabsTrigger>
          <TabsTrigger value={`${path}/accounts`} id="accounts">
            Accounts
          </TabsTrigger>
          <TabsTrigger value={`${path}/users`} id="users">
            Users
          </TabsTrigger>
        </TabsList>
      </TabsRoot>

      <PageBody>
        <Switch>
          {hasS5SegmentsList ? (
            <Redirect from={`${path}/segments`} to="/audience/segments-v2" />
          ) : (
            <Route path={`${path}/segments`} component={SegmentList} />
          )}
          <Route path={`${path}/users`} component={UserList} />
          <Redirect to={`${path}/segments`} />
        </Switch>
      </PageBody>
    </IndexPage>
  );
};

Audiences.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  canCreateSegments: PropTypes.bool,
  hasS5SegmentsList: PropTypes.bool,
  hasUserIdUpload: PropTypes.bool,
  onUploadSegmentUserIds: PropTypes.func,
};

const mapStateToProps = state => ({
  canCreateSegments:
    selectUserRole(state, selectUserId(state)) !== ACCOUNT_PERMISSIONS.EDITOR,
  hasS5SegmentsList: selectAccountFeature(state, S5_SEGMENTS_LIST),
  hasUserIdUpload: selectAccountFeature(state, 'SEGMENT_USER_ID_UPLOAD'),
});

const mapDispatchToProps = dispatch => ({
  onUploadSegmentUserIds: body =>
    asPromised(dispatch, uploadSegmentUserIds(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audiences);
