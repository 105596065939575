import hash from 'object-hash';
import moment from 'moment';
import { STEP_TYPES } from 'constants/stepTypes';
import { FLOW_ACTION_TYPES } from 'constants/view';

const intRegEx = /^([+-])?(\d+|Infinity)$/;

/**
 * Given a stepType, returns the name of its account-level key in Firebase
 * (ex. Modals are stored under "flows" in Firebase)
 *
 * @param stepType
 * @returns {*}
 */

export function getFirebaseStepType(stepType) {
  const firebaseStepTypes = {
    journey: 'journeys',
    modal: 'flows',
    hotspots: 'hotspot-groups',
    coachmarks: 'coachmark-groups',
  };
  return firebaseStepTypes[stepType];
}

export function safeGet(number) {
  return Number.isNaN(number) ? 0 : number;
}

/**
 * Given a string, determines if we should consider it to be a
 * Unix timestamp or just a number.  Will not detect ISO timestamps
 *
 * @param value
 * @returns {boolean}
 */
export function isUnixTimestamp(value) {
  if (intRegEx.test(value)) {
    return String(value).length >= 9;
  }

  return false;
}

/**
 * Given a string, determines if we should consider it to be a
 * timestamp or just a number.  Logic ported directly from
 * my.appcues.com CoffeeScript
 *
 * @param value
 * @returns {boolean}
 */
export function isTimestamp(value) {
  let val = value;

  if (intRegEx.test(val)) {
    val = Number(val);
  }

  switch (typeof val) {
    case 'string':
      return !Number.isNaN(new Date(Date.parse(val)).getTime());
    case 'number':
      // Verify the number is most likely a timestamp in
      // seconds/milliseconds (i.e. has 9 or more digits).
      return String(val).length >= 9;
    default:
      return false;
  }
}

/**
 * Given a Unix timestamp, converts timestamp to Unix timestamp in Milliseconds
 * based on reasonable assumptions
 *
 * @param value
 * @returns {number}
 */
export function convertToMilliseconds(value) {
  const { length } = `${value}`;

  if (length >= 18) return value / 1000000;
  if (length <= 10) return value * 1000;
  return Number(value);
}

/**
 * Given an input value, formats to a readable date depending on the input type
 *
 * @param date
 * @returns {string}
 */
export function formatDate(date, format = 'LL') {
  const dateInMilliseconds = convertToMilliseconds(date);

  if (!Number.isNaN(Number(date))) {
    return moment(dateInMilliseconds, 'x').format(format);
  }

  return moment(dateInMilliseconds).format(format);
}

/**
 * Given a value, determines whether the input is a number,
 * or a string that resembles a number
 *
 * @param value
 * @returns {boolean}
 */

export function isNumber(value) {
  return (
    typeof value === typeof 2 ||
    (value !== true && value !== false && !Number.isNaN(Number(value)))
  );
}

/**
 * Given a value, determines whether the input is a Boolean,
 * or a string that resembles a Boolean
 *
 * @param value
 * @returns {boolean}
 */

export function isBoolean(value) {
  return typeof value === typeof true || value === 'true' || value === 'false';
}

/**
 * Returns an object without the keys listed in the `excluded` argument.
 *
 * @param obj
 * @param excluded
 * @returns {*}
 */

export function blacklist(obj, excluded) {
  return Object.keys(obj || []).reduce((prev, prop) => {
    if (!excluded || excluded.includes(prop)) {
      return prev;
    }
    return {
      ...prev,
      [prop]: obj[prop],
    };
  }, {});
}

/**
 * In the absence of `Set`, we provide a helper function which ensures no two objects with
 * the same `id` occur in an array;
 *
 * @param items
 * @returns {*}
 */

export function getContent(stepChild) {
  return (
    (stepChild.content || '').replace(
      /(<style([\S\s]*?)>([\S\s]*?)<\/style>)|<(?:.|\n)*?>/gm,
      ''
    ) || stepChild.id
  );
}

/**
 * Removes any keys with null values from an object, just like Firebase does.  For example...
 *
 * { name: "some name", segmentId: null }
 *
 * ...becomes...
 *
 * { name: "some name" }
 *
 * @param obj
 * @returns {*}
 */

export function stripNullKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.filter(it => it !== null).map(stripNullKeys);
  }
  if (obj instanceof Object) {
    return Object.keys(obj).reduce((prev, key) => {
      if (obj[key] !== null) {
        if (obj[key] instanceof Object) {
          const child = stripNullKeys(obj[key]);
          if (child && Object.keys(child).length > 0) {
            return {
              ...prev,
              [key]: child,
            };
          }
        } else {
          return {
            ...prev,
            [key]: obj[key],
          };
        }
      }
      return prev;
    }, {});
  }
  return obj;
}

export function mapErrorCodeToShortDescription(errorCode) {
  if (errorCode === '710') {
    return 'Reached annotation retry limit.';
  }
  if (errorCode === '810') {
    return 'Element not found.';
  }
  if (errorCode === '811') {
    return 'Multiple elements found.';
  }
  if (errorCode === '812') {
    return 'Element found but not visible.';
  }
  return 'Unknown error.';
}

/**
 * Given a stepChildId and the errors associated with the parent step, function
 * calculates and returns the index of the error associated with the stepChildId.
 * Errors are also assigned indexes based on its associated stepChild, but since
 * stepChilds do not always have indexes, this function is an appropriate safeguard.
 *
 * @param stepChildId
 * @param errorDetails
 * @returns {*}
 */
export function getErrorStepIndex(stepChildId, errorDetails) {
  let index = 0;
  const indexing = {};
  errorDetails.forEach(step => {
    if (typeof indexing[step.id] !== 'number') {
      indexing[step.id] = index;
      index += 1;
    }
  });
  return indexing[stepChildId];
}

/**
 * Returns the user-friendly name of a step, deriving it from the name of its parent
 * Journey and type if necessary.
 *
 * @param stepId
 * @param steps
 * @param includeType
 * @returns {*}
 */

export function getStepLabel(stepId, steps, includeType = true) {
  const flow = Object.values(steps).find(({ steps: flowSteps }) =>
    Object.keys(flowSteps || {}).includes(stepId)
  );

  if (flow) {
    const {
      name,
      steps: {
        [stepId]: { stepType, index },
      },
    } = flow;
    const displayStepType = STEP_TYPES[stepType] && STEP_TYPES[stepType].label;
    return `${name} ${
      includeType && `(Step ${index + 1}, ${displayStepType || stepType})`
    }`;
  }

  if (steps[stepId]) {
    return steps[stepId].name;
  }

  return stepId;
}

/**
 * Generates a Backbone-compatible URL for the specified step and route given
 * an object formatted like a Journey Step (e.g. { stepType, id })
 *
 * @param {stepType, id}
 * @param route
 * @returns {*}
 */

export function getWebappUrl({ stepType, id }, route) {
  if (stepType && id && route) {
    return `/${STEP_TYPES[stepType].contentType}/${id}/${route}`;
  }
  return '';
}

/**
 * Capitalizes the first letter of each word (as separated by delimiter)
 * and returns a new string.
 *
 * @param str
 * @param delimiter
 * @returns {string}
 */

export const titleCase = (str, delimiter = '_') => {
  return (str || 0)
    .toLocaleString()
    .toLowerCase()
    .split(delimiter)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts an "encoded" property name (e.g. camel or snake case)
 * and returns a friendly-looking version of it.
 *
 * @param prop
 */
export const getPropertyDisplayName = prop => {
  return (
    prop
      // Remove custom prefixes.
      ?.replace(/^_appcuesForm_/g, 'Form Response: ')
      .replace(/^_appcuesSatisfaction_/g, '')
      // Replace initial _ with no space.
      .replace(/^_/, '')
      // Replace - _ . + with spaces.
      .replace(/[+._-]/g, ' ')
      // Replace "abcD" with "abc D", "abcDEF" with "abc DEF".
      .replace(/([a-z]+)([A-Z]+)/g, (m, g1, g2) => {
        return `${g1} ${g2}`;
      })
      // Replace "Abcd" with " Abcd", discarding a starting space if it's there.
      .replace(/(\s?)([A-Z])([a-z]+)/g, (m, g1, g2, g3) => {
        return ` ${g2}${g3}`;
      })
      // Remove leading and trailing whitespace.
      .trim()
      // Lower case the whole thing to make it sentence case
      .toLowerCase()
      // Take common all-caps acronyms and capitalize them.
      .replace(/\b(id|url|os|ip|eid|fb|crm|uuid|nps)\b/gi, (m, g1) => {
        return `${g1.toUpperCase()}`;
      })
      // Upper case the first character of each space separated word.
      .replace(/(\w)(\w*)/g, (m, g1, g2) => {
        return `${g1.toUpperCase()}${g2}`;
      })
      .trim()
  );
};

/**
 * Builds a share permalink and returns the href.
 *
 * @param previewUrl
 * @param stepId
 * @returns {string}
 */
export const buildPermalink = (previewUrl, stepId) => {
  const permalink = document.createElement('a');
  permalink.href = previewUrl;
  permalink.search = `?appcue=${stepId}`;
  return permalink.href;
};

/**
 * Builds a link to the shareable preview page
 *
 * @param contentType
 * @param stepId
 * @returns {string}
 */
export const buildShareablePreviewLink = (contentType, stepId) => {
  return `${window.location.origin}/${contentType}s/${stepId}/preview`;
};

/**
 * Checks that value is not undefined or an empty string.
 *
 * @param value
 */

export const checkIsValueDefinedAndValid = value => {
  return ![undefined, ''].includes(value);
};

/**
 * Determines a user property's grouping based on its name
 *
 * @param propName
 * @returns {*}
 */

export const getOptGroup = propName => {
  if (!propName) {
    return null;
  }
  if (/^(_deviceType|_operatingSystem|_browser)/.test(propName)) {
    return 'device';
  }
  if (propName.startsWith('_appcuesForm_')) {
    return 'form';
  }
  if (propName.startsWith('_appcuesSatisfaction_')) {
    return 'satisfaction';
  }
  if (propName.startsWith('_')) {
    return 'auto';
  }
  return 'normal';
};

/**
 * Determines the data type of the values corresponding to a profile attribute
 *
 * @param values
 * @returns {*}
 */
export const setDataType = values => {
  const nonNullValues = values.filter(
    value => value !== null && value !== 'null' && value !== ''
  );
  // Null values should be counted as valid empty values, regardless of data type
  if (nonNullValues.length === 0) {
    return 'Unknown Data Type';
  }

  if (nonNullValues.every(item => isTimestamp(item))) {
    return 'Date';
  }
  if (nonNullValues.every(item => isBoolean(item))) {
    return 'True/False';
  }
  if (nonNullValues.every(item => isNumber(item))) {
    return 'Number';
  }
  return 'Text';
};

/**
 * Determines if the provided string is a valid Javascript
 * regex or malformed.
 *
 * @param string
 */

export const isValidRegex = string => {
  try {
    // eslint-disable-next-line no-new
    new RegExp(string);
    return true;
  } catch {
    return false;
  }
};

/**
 * Returns a utility function that allows you to compare
 * two arrays.
 *
 * @param array
 */

export const hashArray = array => {
  return hash(array, { unorderedArrays: true });
};

/**
 *  Builds the URL path for a flow
 *  and its corresponding user action type
 *
 * @param stepType
 * @param contentId
 * @param action (one of "conditions", "analytics", "edit")
 */

export const buildFlowActionPath = (contentType, contentId, action) => {
  const path = `/${contentType}/${contentId}`;

  // Set default action to settings since all
  // step types have a settings page
  if (!action) {
    return `${path}/settings`;
  }
  if (action === FLOW_ACTION_TYPES.BUILD) {
    return `/edit-in-chrome/stepGroup/${contentId}`;
  }

  return `${path}/${action}`;
};

/**
 *  Returns the sorting strategy of Array.sort
 *  based on an ascending or descending sort.
 *
 * @param a - the first item to sort
 * @param b - the second item to sort
 * @param isAscending - boolean
 */

const sortStrategy = (a, b, isAscending) => {
  if (isAscending) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  return a > b ? -1 : a < b ? 1 : 0;
};

export const sortAscending = (a, b) => {
  return sortStrategy(a, b, true);
};

export const sortDescending = (a, b) => {
  return sortStrategy(a, b, false);
};

const isStringOrNaN = value => {
  return typeof value === 'string' || Number.isNaN(value);
};

/**
 * Generates a comparator that when passed to Array.sort will sort
 * an array of Objects by a specified key, in ascending order
 *
 * @param key
 * @returns {function(*, *): number}
 */
export const sortByKey =
  (key, direction = 'asc') =>
  (a, b) => {
    if (isStringOrNaN(a[key]) || isStringOrNaN(b[key])) {
      if (direction === 'asc') {
        return `${a[key]}`
          .toLocaleLowerCase()
          .localeCompare(`${b[key]}`.toLocaleLowerCase());
      }
      return `${b[key]}`
        .toLocaleLowerCase()
        .localeCompare(`${a[key]}`.toLocaleLowerCase());
    }
    if (direction === 'asc') {
      return a[key] - b[key];
    }
    return b[key] - a[key];
  };

/**
 * Generates a comparator that when passed to Array.sort will sort
 * an array of Objects by a specified key, including number values and booleans
 *
 * @param key
 * @returns {function(*, *): number}
 */
export const sortBy = key => (a, b) => {
  if (typeof a[key] === 'boolean') {
    return b[key] - a[key];
  }
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

/**
 * Rebuilds a URL string for a given location object and querystring
 *
 * @param location
 * @param queryString
 * @returns {string}
 */

export const getUrlWithQueryString = (location, queryString) =>
  `${location.protocol}//${location.host}${location.pathname}${
    queryString ? `?${queryString}` : ''
  }`;

/**
 * Checks whether the domain specified is included in the account's
 * `installedDomains`
 * @param  {string} domain
 * @param  {obj} installedDomains    installedDomains from acct meta
 * @return {boolean}
 */
export const isInstalledAtDomain = (domain, installedDomains) => {
  return installedDomains.some(installed => domain.includes(installed));
};

/**
 * Replaces variables in string identified via template indicators
 * e.g. replaces "#{item}" with "args[item]"
 * @param  {string} templateString
 * @param  {obj} templateArgs
 * @return {string}
 */
export const replaceTemplatedString = (templateString, templateArgs = {}) => {
  const templateRegex = /#{(\w+)}/g;
  return templateString.replace(
    templateRegex,
    (completeMatch, specificMatch) => templateArgs[specificMatch]
  );
};

/**
 * Partially apply arguments to function
 *
 * @param {function} callback - Function to partially bind
 * @param {...any} args - Arguments to bind
 * @returns {function} Partially bound function
 */
export const partial =
  (callback, ...args) =>
  (...rest) =>
    callback(...args, ...rest);

/**
 * Get theme value for key in styled components
 *
 * @param {string} key - Key to get from theme
 * @returns {function} Theme style getter key
 */
export const getThemeFor =
  key =>
  ({ theme }) =>
    theme[key];

const DATE_PROPERTIES = new Set([
  '_firstSeenAt',
  '_lastSeenAt',
  'last_seen',
  '_updatedAt',
]);

export const formatPropertyValue = (name, value) => {
  if (value === null || value === undefined || value === '') {
    return `-`;
  }
  if (!DATE_PROPERTIES.has(name.replace('p:', ''))) {
    return `${value}`;
  }

  try {
    return isUnixTimestamp(value)
      ? formatDate(value, 'MMM D, YYYY h:mm A')
      : formatDate(new Date(value), 'MMM D, YYYY h:mm A');
  } catch {
    return `${value}`;
  }
};
