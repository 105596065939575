import qs from 'qs';

/**
 * Comparator by index property
 *
 * @param {object} first - First entity
 * @param {object} second - Second entity
 * @return {number} Ascending comparison
 */
const byIndex = ({ index: a }, { index: b }) => a - b;

/**
 * Get the first step IDs from a given entity type
 *
 * @param {("flows")} type - Entity type
 * @param {Experience} experience - Experience entity
 * @return {Screenshot} Type and IDs of first step
 */
export const getFirstStepIds = (type, experience) => {
  switch (type) {
    // Return IDs if they all exist, otherwise return null
    case 'flows': {
      const { id, steps } = experience ?? {};

      // Filter out non-UI steps and sort by index property
      const groups = steps ?? {};
      const [group = {}] = Object.values(groups)
        .filter(({ stepType }) => stepType !== 'action')
        .sort(byIndex);

      // Sort the different step types by index property
      // NOTE: May need to include experience_steps and other child step fields
      const children = (group.steps || group.hotspots) ?? {};
      const [child = {}] = Object.values(children).sort(byIndex);

      return id && group.id && child.id
        ? {
            id,
            stepGroupId: group.id,
            stepChildId: child.id,
            type,
          }
        : null;
    }

    case 'pins':
    case 'banner': {
      const {
        id,
        steps: [{ id: stepId } = {}],
      } = experience ?? {};

      return id && stepId ? { id, stepId, type } : null;
    }

    case 'mobile': {
      const [child] = experience.steps.flatMap(step => step?.children) ?? [];

      return experience?.id && child
        ? { id: experience.id, stepId: child.id, type }
        : null;
    }

    // Return null for unknown types
    default:
      return null;
  }
};

/**
 * Build image service screenshot URL for given screenshot and action
 *
 * @param {Screenshot} screenshot - Type and IDs for screenshot
 * @return {function} URL builder for given screenshot
 *
 *   @param {{'purge'|'presign'}} URL action
 *   @return {string} Screenshot URL
 */
export const buildScreenshotUrlFor = screenshot => action => {
  const { id, stepGroupId, stepChildId, type } = screenshot?.step ?? {};

  const version = screenshot?.version ?? 'latest';
  const size = screenshot?.size ?? 'medium';

  switch (type) {
    case 'flows': {
      const path = `versions/${version}/flows/${id}/steps/${stepGroupId}/children/${stepChildId}`;
      const query = qs.stringify({ size, locale: screenshot?.locale });
      return `${path}/${action}?${query}`;
    }

    default:
      return null;
  }
};

export const getImageHashKeys = ({ step, locale, size, version, state }) => {
  const hashKeys = { ...step, locale, size, version, state };
  return step && Object.values(hashKeys).join('');
};
