import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel as UIPanel, H3, PanelHeader } from '@studio/legacy-components';

import EventSearchBar from 'components/Insights/Common/EventSearchBar';
import TableWithAnalytics from './TableWithAnalytics';
import EventsTable from './EventsTableProvider';

export const Panel = ({
  source,
  startTime,
  endTime,
  events,
  segmentId,
  pageSize,
  hasConfigurationPage,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <UIPanel>
      <PanelHeader>
        <H3>Events</H3>
        <EventSearchBar placeholder="Search" onChange={setSearchTerm} />
      </PanelHeader>
      {hasConfigurationPage ? (
        <EventsTable
          names={events.map(({ event }) => event)}
          stepIds={events.map(({ stepId }) => stepId)}
          startTime={startTime}
          endTime={endTime}
          searchTerm={searchTerm}
          contentType={events[0].source}
          contentId={events[0].id}
        />
      ) : (
        <TableWithAnalytics
          source={source}
          startTime={startTime}
          endTime={endTime}
          events={events}
          segmentId={segmentId}
          pageSize={pageSize}
          searchTerm={searchTerm}
        />
      )}
    </UIPanel>
  );
};

Panel.propTypes = {
  source: PropTypes.string,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string,
      id: PropTypes.string,
      source: PropTypes.string,
      stepId: PropTypes.string,
    })
  ),
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
  hasConfigurationPage: PropTypes.bool,
};

export default Panel;
