import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Tooltip } from '@appcues/sonar';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion';

function FlowMetricTooltip({ children }) {
  return (
    <Tooltip
      placement="top"
      content={
        <Text size="small" lineHeight="large" colorToken="foreground-tooltip">
          {children}
        </Text>
      }
      size="regular"
    >
      <Icon
        icon={faCircleQuestion}
        colorToken="foreground-button-tertiary-default"
      />
    </Tooltip>
  );
}

FlowMetricTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlowMetricTooltip;
