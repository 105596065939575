import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PageItem = styled.button`
  background-color: var(--pagination);
  border-radius: 6px;
  border: 1px solid var(--pagination);
  display: block;
  height: 30px;
  margin: 0 2px;
  min-width: 30px;
  padding: 4px;
  text-align: center;

  &:hover,
  &:focus {
    background-color: var(--pagination-focus);
    border: 1px solid var(--pagination-focus);
    cursor: pointer;
  }

  &:disabled {
    background-color: transparent;
    border: 0;
    pointer-events: none;
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: var(--white);
    border: 1px solid var(--pagination-selected);
    color: var(--pagination-selected);

    &:hover, &:focus {
      background-color: var(--pagination-selected);
      border: 1px solid var(--pagination-selected);
      color: var(--white);
    }
  `}
`;

const SkipButton = styled(PageItem)`
  background-color: transparent;
  border: none;
  color: var(--pagination-arrow);

  ${Icon} {
    font-size: var(--small);
  }
`;

/**
 * Pagination Component
 * @params {number} pageCount - the number of total "pages"
 * @params {number} currentIndex - the current index/page as set by the parent container
 * @params {number} boundary - the number of pages initially displayed before truncating (ellipsis)
 * @params {function} onChange - passes up the selected "data-index" (ie: selected page index)
 */
const Pagination = ({
  pageCount = 1,
  currentIndex = 0,
  onChange,
  boundary = 5,
}) => {
  const handleClick = value => () => onChange(value);

  const pages = Array.from({ length: pageCount }, (_, i) => i);

  const preTruncation = currentIndex + 1 >= boundary && pageCount > boundary;
  const isNearEnd = pageCount - currentIndex + 1 <= boundary;
  const postTruncation = pageCount > boundary && !isNearEnd;
  let slice = pages.slice(0, boundary);

  if (preTruncation && !isNearEnd) {
    slice = pages.slice(currentIndex - 1, currentIndex + 2);
  }
  if (preTruncation && isNearEnd) {
    slice = pages.slice(pageCount - boundary, currentIndex + boundary);
  }

  return (
    <PaginationWrapper role="navigation" aria-label="pagination navigator">
      <SkipButton
        aria-label="Go to previous page"
        onClick={handleClick(currentIndex - 1)}
        disabled={currentIndex === 0}
      >
        <Icon icon="chevron-left" />
      </SkipButton>
      {preTruncation && (
        <>
          <PageItem
            selected={currentIndex === 0}
            aria-selected={currentIndex === 0}
            key={0}
            aria-label="Go to Page 1"
            onClick={handleClick(0)}
          >
            1
          </PageItem>
          <PageItem disabled>…</PageItem>
        </>
      )}
      {slice.map(pageVal => (
        <PageItem
          selected={currentIndex === pageVal}
          aria-selected={currentIndex === pageVal}
          key={pageVal}
          data-index={pageVal}
          aria-label={`Go to Page ${pageVal + 1}`}
          onClick={handleClick(pageVal)}
        >
          {pageVal + 1}
        </PageItem>
      ))}

      {postTruncation && pageCount !== currentIndex + 1 && (
        <>
          <PageItem disabled>…</PageItem>
          <PageItem
            selected={currentIndex + 1 === pageCount}
            aria-selected={currentIndex + 1 === pageCount}
            aria-label={`Go to Page ${pageCount}`}
            onClick={handleClick(pageCount - 1)}
          >
            {pageCount}
          </PageItem>
        </>
      )}
      <SkipButton
        aria-label="Go to next page"
        onClick={handleClick(currentIndex + 1)}
        disabled={currentIndex + 1 === pageCount}
      >
        <Icon icon="chevron-right" />
      </SkipButton>
    </PaginationWrapper>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
  currentIndex: PropTypes.number,
  boundary: PropTypes.number,
  onChange: PropTypes.func,
};

export const PaginationContainer = styled.div`
  position: relative;
`;

const Message = styled.span`
  font-weight: var(--bold);
  position: absolute;
  right: 24px;
  top: calc(50% - 8px);
`;

export const PaginationMessage = ({
  currentIndex,
  pageSize,
  total,
  className,
}) => {
  return (
    <Message className={className}>
      Displaying {currentIndex * pageSize + 1} -{' '}
      {Math.min(currentIndex * pageSize + pageSize, total)} of {total}
    </Message>
  );
};

PaginationMessage.propTypes = {
  currentIndex: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  className: PropTypes.string,
};

export default Pagination;
