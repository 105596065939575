import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Panel,
  PanelHeader,
  PanelTitle,
  H3,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  PanelFooter,
  Spinner,
  Link,
} from '@studio/legacy-components';
import NoData from 'next/assets/images/no-data.svg';
import Pagination, { PaginationMessage } from 'next/components/Pagination';
import { readAnalytics } from 'next/entities/analytics';
import { selectExperienceRecentUsers } from 'next/lib/selectors';
import { PIN_BUTTON_TYPE } from 'next/entities/experiences';
import { queryExperienceRecentUsers } from 'next/client/queries';
import { format } from 'next/lib/date';
import {
  TableWrapper,
  NoResultsMessage,
  LoaderWrapper,
  CapitalizeTd,
} from './RecentUsers.styled';

export function RecentUsers({
  onLoad,
  recentUsers,
  limit,
  sortColumn,
  sortDirection,
  onSortChange,
  experienceType,
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const isLoading = !recentUsers;
  const hasNoResults = recentUsers?.length === 0;

  const visibleUsers = useMemo(() => {
    const start = currentPage * limit;
    const end = start + limit;

    return recentUsers?.slice(start, end);
  }, [currentPage, limit, recentUsers]);

  const handleSortChange = column => {
    onSortChange(column);
    setCurrentPage(0);
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Recent Users</H3>
        </PanelTitle>
      </PanelHeader>
      <TableWrapper>
        {isLoading && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
        {hasNoResults && (
          <NoResultsMessage>
            <img src={NoData} alt="No data found" />
            No results, please try different filters.
          </NoResultsMessage>
        )}
        {!isLoading && (
          <Table>
            <Thead>
              <Tr>
                <Th
                  title="User ID"
                  sortable
                  sorted={sortColumn === 'user_id' ? sortDirection : null}
                  onClick={() => handleSortChange('user_id')}
                >
                  User ID
                </Th>
                {experienceType === 'Pin' && (
                  <Th
                    title={`${experienceType} name`}
                    sortable
                    sorted={sortColumn === 'name' ? sortDirection : null}
                    onClick={() => handleSortChange('name')}
                  >
                    {experienceType} name
                  </Th>
                )}
                <Th
                  title="Event time"
                  sortable
                  sorted={sortColumn === 'timestamp' ? sortDirection : null}
                  onClick={() => handleSortChange('timestamp')}
                >
                  Event time
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {visibleUsers.map(
                ({ user_id: user, timestamp, name, type }, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tr key={user + timestamp + i}>
                    <Td>
                      <Link to={`/users/${encodeURIComponent(user)}`}>
                        {user}
                      </Link>
                    </Td>
                    {experienceType === 'Pin' && (
                      <CapitalizeTd>{name}</CapitalizeTd>
                    )}
                    <Td>
                      {experienceType === 'Pin'
                        ? type === PIN_BUTTON_TYPE
                          ? 'Clicked'
                          : 'Opened'
                        : ''}{' '}
                      {format(timestamp, 'MMM DD, YYYY [at] h:mmA')}
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        )}
      </TableWrapper>
      {recentUsers?.length > 0 && (
        <PanelFooter>
          <Pagination
            pageCount={Math.ceil(recentUsers.length / limit)}
            currentIndex={currentPage}
            onChange={setCurrentPage}
          />
          <PaginationMessage
            total={recentUsers.length}
            currentIndex={currentPage}
            pageSize={limit}
          />
        </PanelFooter>
      )}
    </Panel>
  );
}

RecentUsers.propTypes = {
  onLoad: PropTypes.func,
  recentUsers: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      timestamp: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  limit: PropTypes.number,
  sortColumn: PropTypes.oneOf(['user_id', 'name', 'timestamp']),
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  onSortChange: PropTypes.func,
  experienceType: PropTypes.string,
};

const getQueryParams = ({
  experienceId,
  startTime,
  endTime,
  segmentId,
  sortColumn,
  sortDirection,
  experienceType,
}) => ({
  experienceId,
  startTime,
  endTime,
  segmentId,
  sortColumn,
  sortDirection,
  experienceType,
});

const mapStateToProps = (state, ownProps) => {
  const { experienceId } = ownProps;

  return {
    recentUsers: selectExperienceRecentUsers(
      state,
      getQueryParams(ownProps),
      experienceId
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const query = queryExperienceRecentUsers(queryParams);

  return {
    onLoad: () => dispatch(readAnalytics(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentUsers);
