import { connect } from 'react-redux';
import { SpoofControl } from '@studio/legacy-navigation';

import { selectSpoofing, start } from 'next/entities/spoofing';
import { selectUser } from 'next/entities/user';

const mapStateToProps = state => ({
  spoofing: selectSpoofing(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  onSpoof: start,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpoofControl);
