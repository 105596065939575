import React from 'react';
import { Row } from '@appcues/component-library';
import MetricCard from 'components/Common/Metric/MetricCard';

const OverviewSection = props => {
  const {
    checklistAnalytics: {
      data: { checklistShown, checklistCompleted, checklistSkipped },
    },
  } = props;

  const percentCompleted =
    !checklistShown || checklistShown === 0
      ? '0%'
      : `${((checklistCompleted / checklistShown) * 100).toFixed(1)}%`;
  const percentSkipped =
    !checklistShown || checklistShown === 0
      ? '0%'
      : `${((checklistSkipped / checklistShown) * 100).toFixed(1)}%`;

  return (
    <Row marginBottom={20} alignItems="stretch">
      <MetricCard
        name="Users reached"
        metric={checklistShown}
        caption="Users that saw this checklist"
      />
      <MetricCard
        name="Users completed"
        metric={percentCompleted}
        caption="Users who saw and completed all items"
      />
      <MetricCard
        name="Users skipped"
        metric={percentSkipped}
        caption="Users who saw and skipped this checklist"
      />
    </Row>
  );
};

export default OverviewSection;
