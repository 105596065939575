import React from 'react';
import PropTypes from 'prop-types';
import { NavItem as NavigationNavItem } from '@studio/legacy-navigation';
import { NavLink } from 'react-router-dom';

const NavItem = ({ to, external, ...unhandledProps }) => {
  return <NavigationNavItem NavLink={NavLink} to={to} {...unhandledProps} />;
};

NavItem.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
};

export default NavItem;
