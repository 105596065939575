import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Modal,
  NegativeNotice,
  Overflow,
} from '@studio/legacy-components';

import CreateExperienceForm from './CreateExperienceForm';

const CreateExperienceModal = ({
  onClose,
  onSubmit,
  visible,
  disabled,
  hasError,
  hasMobile,
}) => {
  const handleSubmit = (event, values) => {
    event.preventDefault();
    return onSubmit(values);
  };

  return (
    <Modal onClose={onClose} size="m" visible={visible}>
      <Overflow>
        <Heading>Create a Flow</Heading>
        {hasError && (
          <NegativeNotice>
            We're sorry, something went wrong on our end. Try again.
          </NegativeNotice>
        )}
        <CreateExperienceForm
          hasMobile={hasMobile}
          disabled={disabled}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      </Overflow>
    </Modal>
  );
};

CreateExperienceModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasMobile: PropTypes.bool,
};

export default CreateExperienceModal;
