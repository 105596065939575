import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import { CPanel, CTab, H2 } from '@appcues/component-library';

import { Link } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import EmptyState from 'components/Common/EmptyState';

import { selectAccountUserEvents } from 'selectors/event-names';
import {
  selectProfileCustomProperties,
  selectProfileAutoProperties,
} from 'selectors/profile-attributes';
import PropertiesList from './PropertiesList';
import EventsList from './EventsList';

const INSTALL_LINK = '/settings/installation';

export const emptyStatePropsMap = {
  events: {
    header: 'No events yet',
    iconName: 'users',
    className: 'no-events-msg',
    body: <Link to={INSTALL_LINK}>Learn how to send events.</Link>,
  },
  autoProperties: {
    header: 'No auto-properties yet',
    iconName: 'chart-bar',
    className: 'no-autoproperties-msg',
    body: <Link to={INSTALL_LINK}>Learn how to send auto-properties.</Link>,
  },
  customProperties: {
    header: 'No custom properties yet',
    iconName: 'chart-bar',
    className: 'no-customproperties-msg',
    body: <Link to={INSTALL_LINK}>Learn how to send custom properties.</Link>,
  },
};

const TABS = {
  EVENTS: 'events',
  AUTO_PROPERTIES: 'auto-properties',
  CUSTOM_PROPERTIES: 'custom-properties',
};

export class PropertiesAndEvents extends Component {
  constructor(props) {
    super(props);

    const { tabState } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    let initialTabId = TABS.EVENTS;
    switch (tabState) {
      case 'customProperties':
        initialTabId = TABS.CUSTOM_PROPERTIES;
        break;
      case 'autoProperties':
        initialTabId = TABS.AUTO_PROPERTIES;
        break;
      default:
        break;
    }
    this.state = {
      tabId: initialTabId,
    };
  }

  EventsList = () => {
    const { userEvents } = this.props;
    return Object.keys(userEvents).length > 0 ? (
      <CPanel>
        <EventsList userEvents={userEvents} />
      </CPanel>
    ) : (
      <EmptyState {...emptyStatePropsMap.events} />
    );
  };

  CustomProperties = () => {
    const { customProperties } = this.props;
    return Object.keys(customProperties).length > 0 ? (
      <CPanel>
        <PropertiesList properties={customProperties} />
      </CPanel>
    ) : (
      <EmptyState {...emptyStatePropsMap.customProperties} />
    );
  };

  AutoProperties = () => {
    const { autoProperties } = this.props;
    return Object.keys(autoProperties).length > 0 ? (
      <CPanel>
        <PropertiesList properties={autoProperties} isAutoProperties />
      </CPanel>
    ) : (
      <EmptyState {...emptyStatePropsMap.autoProperties} />
    );
  };

  onChangeTab = tabId => this.setState({ tabId });

  render() {
    const { tabId } = this.state;

    return (
      <>
        <H2 type="secondary">Events and properties</H2>
        <CTab.Bar value={tabId} onChange={this.onChangeTab} marginBottom={16}>
          <CTab id={TABS.EVENTS}>Events</CTab>
          <CTab id={TABS.CUSTOM_PROPERTIES}>Custom properties</CTab>
          <CTab id={TABS.AUTO_PROPERTIES}>Auto-properties</CTab>
        </CTab.Bar>
        {tabId === TABS.EVENTS && <this.EventsList />}
        {tabId === TABS.CUSTOM_PROPERTIES && <this.CustomProperties />}
        {tabId === TABS.AUTO_PROPERTIES && <this.AutoProperties />}
        <DocumentTitle title="Events and Properties | Settings | Appcues" />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    autoProperties: selectProfileAutoProperties(state),
    customProperties: selectProfileCustomProperties(state),
    userEvents: selectAccountUserEvents(state),
  };
}

export default connect(mapStateToProps)(PropertiesAndEvents);
