import React from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Spinner } from '@appcues/sonar';
import { format } from 'next/lib/date';
import NoData from 'next/assets/images/no-data.svg';
import { getPropertyDisplayName } from 'utils';
import SelectedEventLabel from 'components/Insights/Explorer/Chart/SelectedEventLabel';
import {
  LoadingWrapper,
  NoResultsMessage,
  PaginationWrapper,
  TruncatedText,
} from './styled';
import { UserIdentifierCell } from './UserIdentifierCell';

export default function EventsTable({
  isLoading,
  userProfileIdentifier,
  contentType,
  contentId,
  sortDirection,
  toggleSortDirection,
  userProfiles,
  totalResults,
  pageIndex,
  pageSize,
  setPagination,
}) {
  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  if (!userProfiles || userProfiles?.length === 0) {
    return (
      <NoResultsMessage>
        <img src={NoData} alt="No data found" />
        No results, please try different filters.
      </NoResultsMessage>
    );
  }

  return (
    <>
      <Table.Root>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              {userProfileIdentifier
                ? getPropertyDisplayName(userProfileIdentifier)
                : 'User ID'}
            </Table.Th>
            <Table.Th>Event Name</Table.Th>
            {contentType !== 'mobile' && <Table.Th>URL</Table.Th>}
            <Table.Th
              sortable
              sortDirection={sortDirection}
              onClick={toggleSortDirection}
            >
              Last Triggered
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {userProfiles?.map(userOnEvent => (
            <Table.Tr
              key={`${userOnEvent.userId}_${userOnEvent.event.timestamp}`}
            >
              <Table.Td>
                <UserIdentifierCell
                  userId={userOnEvent.userId}
                  properties={{
                    userId: userOnEvent.userId,
                    ...userOnEvent.profile,
                  }}
                  userProfileIdentifier={userProfileIdentifier}
                />
              </Table.Td>
              <Table.Td>
                <SelectedEventLabel
                  event={userOnEvent.event.name}
                  id={
                    ['checklist', 'flow', 'goals'].includes(contentType)
                      ? contentId
                      : undefined
                  }
                  source={contentType}
                />
              </Table.Td>
              {contentType !== 'mobile' && (
                <Table.Td>
                  <TruncatedText title={userOnEvent.event.url}>
                    {userOnEvent.event.url ?? '-'}
                  </TruncatedText>
                </Table.Td>
              )}
              <Table.Td>
                {userOnEvent.event.timestamp
                  ? format(userOnEvent.event.timestamp, 'lll')
                  : '-'}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table.Root>
      <PaginationWrapper>
        <Pagination
          totalResults={totalResults ?? 0}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
        />
      </PaginationWrapper>
    </>
  );
}

EventsTable.propTypes = {
  isLoading: PropTypes.bool,
  userProfileIdentifier: PropTypes.string,
  contentType: PropTypes.string,
  contentId: PropTypes.string,
  sortDirection: PropTypes.string,
  toggleSortDirection: PropTypes.func,
  userProfiles: PropTypes.arrayOf(PropTypes.object),
  totalResults: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  setPagination: PropTypes.func,
};
