import { format } from 'next/lib/date';

export const queryFlowsMetricsAnalytics = () => ({
  start_time: 1,
  metrics: ['flow_users', 'flow_completed_users'],
  dimensions: ['flow_id'],
});

export const queryExperienceMetricsAnalytics = () => ({
  start_time: 1,
  metrics: ['experience_users', 'experience_completed_users'],
  dimensions: ['experience_id'],
  conditions: [
    [
      'event',
      'in',
      ['appcues:v2:experience_started', 'appcues:v2:experience_completed'],
    ],
    'users',
  ],
});

export const queryBannerAnalyticsChart = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  metrics: ['events'],
  dimensions: ['day', 'event'],
  start_time: format(startTime, 'YYYY-MM-DD'),
  end_time: format(endTime, 'YYYY-MM-DD'),
  conditions: [
    [
      'event',
      'in',
      [
        'appcues:v2:step_seen',
        'appcues:v2:step_interaction',
        'appcues:v2:experience_dismissed',
      ],
    ],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

/**
 * Query for fetching experience steps from the analytics API
 * @param {number} startTime - Number of miliseconds since epoch
 * @param {number} endTime - Number of miliseconds since epoch
 * @param {string} experienceId - Experience unique identifier
 * @param {string} segmentId - Segment unique identifier
 * @returns {Object} Query for domains that had page views in the last year
 */

export const queryExperienceStepAnalytics = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  start_time: startTime,
  end_time: endTime,
  metrics: ['events', 'users', 'most_recent_timestamp'],
  dimensions: ['name', 'experience_id', 'step_id'],
  conditions: [
    [
      'event',
      'in',
      [
        'appcues:v2:step_seen',
        'appcues:v2:step_error',
        'appcues:v2:step_recovered',
        'appcues:v2:step_interaction',
        'appcues:v2:icon_seen',
        'appcues:v2:button_pin_seen',
      ],
    ],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

/**
 * Query for fetching total step_interaction count
 * @param {number} startTime - Number of miliseconds since epoch
 * @param {number} endTime - Number of miliseconds since epoch
 * @param {string} experienceId - Experience unique identifier
 * @param {string} segmentId - Segment unique identifier
 * @returns {Object} total of step_interaction
 */

export const queryExperienceTotalInteraction = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  start_time: startTime,
  end_time: endTime,
  dimensions: ['event'],
  metrics: ['events', 'users'],
  conditions: [
    ['event', 'in', ['appcues:v2:step_interaction', 'appcues:v2:step_seen']],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

/**
 * Query for fetching total experience_dismissed count
 * @param {number} startTime - Number of miliseconds since epoch
 * @param {number} endTime - Number of miliseconds since epoch
 * @param {string} experienceId - Experience unique identifier
 * @param {string} segmentId - Segment unique identifier
 * @returns {Object} total experience_dismissed count
 */

export const queryExperienceTotalDismissed = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  start_time: startTime,
  end_time: endTime,
  metrics: ['events', 'users'],
  conditions: [
    ['event', 'in', ['appcues:v2:experience_dismissed']],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

export const queryExperienceRecentUsers = ({
  experienceId,
  startTime,
  endTime,
  limit,
  segmentId,
  experienceType,
  sortColumn = 'timestamp',
  sortDirection = 'asc',
}) => ({
  conditions: [
    ['experience_id', '==', experienceId],
    [
      'name',
      'in',
      experienceType?.toLowerCase() === 'mobile'
        ? ['appcues:v2:experience_started']
        : ['appcues:v2:step_seen', 'appcues:v2:step_interaction'],
    ],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
  columns: ['user_id', 'timestamp', 'step_id'],
  order_by: [[sortColumn, sortDirection, 'nulls_last']],
  start_time: startTime || 1,
  end_time: endTime,
  limit,
});

export const queryExperienceAnalyticsChart = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  metrics: ['users'],
  dimensions: ['day', 'step_id', 'experience_id'],
  start_time: format(startTime, 'YYYY-MM-DD'),
  end_time: format(endTime, 'YYYY-MM-DD'),
  conditions: [
    ['event', 'in', ['appcues:v2:step_seen', 'appcues:v2:step_interaction']],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

/**
 * Query for fetching users' analytics for an experience
 * @param {Object} queryParams - Number of miliseconds since epoch
 * @param {number} queryParams.startTime - Number of miliseconds since epoch
 * @param {number} queryParams.endTime - Number of miliseconds since epoch
 * @param {string} queryParams.experienceId - Experience unique identifier
 * @param {string} queryParams.segmentId - Segment unique identifier
 *
 */
export const queryExperienceUsers = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  start_time: startTime,
  end_time: endTime,
  metrics: [
    'experience_users',
    'experiences_shown',
    'experience_completed_users',
    'experiences_completed',
  ],
  dimensions: ['experience_id'],
  conditions: [
    [
      'event',
      'in',
      ['appcues:v2:experience_started', 'appcues:v2:experience_completed'],
    ],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

/**
 * Query for fetching goal analytics for an experience
 * @param {Object} queryParams - Number of miliseconds since epoch
 * @param {number} queryParams.startTime - Number of miliseconds since epoch
 * @param {number} queryParams.endTime - Number of miliseconds since epoch
 * @param {string} queryParams.experienceId - Experience unique identifier
 * @param {string} queryParams.goalId - Goal unique identifier
 * @param {string} queryParams.segmentId - Segment unique identifier
 *
 */
export const queryExperienceGoal = ({
  startTime,
  endTime,
  experienceId,
  goalId,
  segmentId,
}) => ({
  start_time: startTime,
  end_time: endTime,
  metrics: ['experience_users', 'goal_achievements'],
  dimensions: ['experience_id'],
  conditions: [
    ['experience_id', '==', experienceId],
    ['goal_id', '==', goalId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

export const queryMobileAnalyticsEvents = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  metrics: ['events', 'users'],
  dimensions: ['name', 'day'],
  start_time: format(startTime, 'YYYY-MM-DD'),
  end_time: format(endTime, 'YYYY-MM-DD'),
  conditions: [
    [
      'event',
      'in',
      ['appcues:v2:experience_started', 'appcues:v2:experience_completed'],
    ],
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

export const queryMobileAnalyticsGoals = ({
  startTime,
  endTime,
  experienceId,
  goalId,
  segmentId,
}) => ({
  metrics: ['experience_users', 'goal_achievements'],
  dimensions: ['day'],
  start_time: startTime,
  end_time: endTime,
  conditions: [
    ['experience_id', '==', experienceId],
    ...(goalId ? [['goal_id', '==', goalId]] : []),
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
});

/*
 * Query for fetching the user shown/completed data for experiences with
 * steps.  Intended for use on the StepBreakdown table
 *
 * @param startTime
 * @param endTime
 * @param experienceId
 * @param segmentId
 */

export const queryExperienceStepBreakdown = ({
  startTime,
  endTime,
  experienceId,
  segmentId,
}) => ({
  conditions: [
    ['experience_id', '==', experienceId],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ],
  dimensions: ['step_id'],
  metrics: ['step_shown_users', 'step_completed_users'],
  start_time: startTime,
  end_time: endTime,
});

export const queryExperienceIssues = ({
  startTime,
  endTime,
  experienceId,
}) => ({
  columns: [
    'timestamp',
    'user_id',
    'step_error_url',
    'step_error_message',
    'step_id',
  ],
  conditions: [
    ['experience_id', '==', experienceId],
    ['name', '==', 'appcues:v2:step_error'],
  ],
  order_by: [['timestamp', 'desc']],
  start_time: startTime,
  end_time: endTime,
  limit: 25,
});

export const queryGoalReachedFromFlowStarted = ({
  accountId,
  goalId,
  flowId,
  conversionWindow,
}) => ({
  type: 'goal_reached_from_flow_started',
  accountId,
  goalId,
  flowId,
  conversionWindow,
});
