import styled from 'styled-components';
import { Table, Td } from '@studio/legacy-components';

export const TableWrapper = styled.div`
  --table-wrapper-min-height: 388px;
  --table-wrapper-max-height: 910px; // 26 rows of 35px height
  min-height: var(--table-wrapper-min-height);
  max-height: var(--table-wrapper-max-height);
  overflow-y: auto;
  position: relative;

  ${Table} {
    table-layout: fixed;

    ${Td} {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--table-wrapper-min-height) - 38px);
  flex-direction: column;
  gap: 16px;
  position: absolute;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--table-wrapper-min-height) - 38px);
  gap: 24px;
  flex-direction: column;
`;

export const CapitalizeTd = styled(Td)`
  text-transform: capitalize;
`;
