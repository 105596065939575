import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Icon,
  SplitButton,
  Tooltip,
  Link,
} from '@studio/legacy-components';
import { fromNow } from 'next/lib/date';
import { selectIsInstalled } from 'next/entities/installed';
import { selectEntitlementsByName } from 'next/entities/entitlements';
import { MOBILE_PUBLISHING, selectFeature } from 'next/entities/features';

import { DropdownListHeading, OptionList, TooltipLabel } from './styled';

export const PublishingActions = ({
  published,
  updatedAt,
  disabled,
  hasChanges,
  onConfirmPublish,
  onConfirmDiscardChanges,
  onConfirmUnpublish,
  hasActiveExperiment,
  hasMobilePublishing = true,
  installed,
  type,
  entitlement,
}) => {
  const blockPublishForLaunchpad = !!(
    type === 'launchpad' &&
    entitlement?.name === 'LAUNCHPADS' &&
    entitlement?.used_units >= entitlement?.allowed_units
  );

  const getLabel = () => {
    if (hasActiveExperiment && published) {
      return (
        <TooltipLabel>
          To publish changes or unpublish the Flow, end the experiment.
        </TooltipLabel>
      );
    }
    if (!installed) {
      return (
        <TooltipLabel>
          In order to publish live experiences to your users, you'll have to
          install Appcues directly into your codebase. Don't worry...it's super
          easy.{' '}
          <Link to="/settings/installation">Click here to learn how.</Link>
        </TooltipLabel>
      );
    }

    if (blockPublishForLaunchpad) {
      return (
        <TooltipLabel>
          Please reach out to support@appcues.com to discuss increasing your
          account's publishing limit.
        </TooltipLabel>
      );
    }
    return 'Contact a team admin or publisher to publish';
  };

  const disablePublishBtn =
    disabled || (!installed && type !== 'mobile') || blockPublishForLaunchpad;

  return (
    <>
      {!published && (
        <Tooltip
          disabled={
            (!disabled || !hasMobilePublishing) &&
            (installed || type === 'mobile') &&
            !blockPublishForLaunchpad
          }
          label={getLabel()}
          placement="bottom"
          persist
          wrapped
        >
          <Button
            disabled={disablePublishBtn}
            kind="primary"
            onClick={data => !disablePublishBtn && onConfirmPublish(data)}
            aria-label="Click to Publish"
          >
            Publish
          </Button>
        </Tooltip>
      )}
      {hasChanges && published && (
        <Tooltip
          persist
          disabled={!disabled}
          label={getLabel()}
          placement="bottom"
        >
          <SplitButton
            kind="positive"
            disabled={disabled || (!installed && type !== 'mobile')}
          >
            <Button
              aria-label="click to push changes"
              disabled={disabled}
              onClick={onConfirmPublish}
            >
              Push changes
            </Button>
            <Dropdown
              aria-label="Open change actions"
              forwardedAs={Button}
              disabled={disabled}
              attachment={
                <>
                  <DropdownListHeading bold>
                    Updated {fromNow(updatedAt)}
                  </DropdownListHeading>
                  <OptionList
                    options={[
                      {
                        label: 'Push changes',
                        onClick: onConfirmPublish,
                      },
                      {
                        label: 'Discard changes',
                        onClick: onConfirmDiscardChanges,
                      },
                      {
                        label: `Unpublish Experience`,
                        onClick: onConfirmUnpublish,
                      },
                    ]}
                  />
                </>
              }
            >
              <Icon icon="chevron-down" />
            </Dropdown>
          </SplitButton>
        </Tooltip>
      )}
      {!hasChanges && published && (
        <Button
          disabled={disabled}
          kind="secondary"
          onClick={onConfirmUnpublish}
          aria-label="Click to Unpublish"
        >
          Unpublish
        </Button>
      )}
    </>
  );
};

PublishingActions.propTypes = {
  published: PropTypes.bool,
  updatedAt: PropTypes.number,
  disabled: PropTypes.bool,
  hasChanges: PropTypes.bool,
  onConfirmPublish: PropTypes.func,
  onConfirmDiscardChanges: PropTypes.func,
  onConfirmUnpublish: PropTypes.func,
  hasActiveExperiment: PropTypes.bool,
  hasMobilePublishing: PropTypes.bool,
  installed: PropTypes.bool,
  type: PropTypes.oneOf(['mobile', 'pins', 'banner', 'launchpad']),
  entitlement: PropTypes.shape({
    name: PropTypes.string,
    used_units: PropTypes.number,
    allowed_units: PropTypes.number,
  }),
};

const mapStateToProps = state => {
  const hasMobilePublishing = selectFeature(state, MOBILE_PUBLISHING);

  const installed = selectIsInstalled(state);

  const entitlement = selectEntitlementsByName(state, 'LAUNCHPADS');

  return {
    hasMobilePublishing,
    installed,
    entitlement,
  };
};

export default connect(mapStateToProps)(PublishingActions);
