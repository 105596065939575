import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  SquareButton,
  Modal,
  Heading,
  Select,
  WarningNotice,
  Icon,
  IconButton,
  Button,
  ModalFooter,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { CONFIGURATION_PAGE } from 'next/entities/features';
import { selectProfileAttributeDropdownOptions } from 'selectors/profile-attributes';
import { selectAccountFeature } from 'reducers/account/features';
import DraggableItem from './DraggableItem';
import { PropertyItem, PropertiesList, PropertyName } from './styled';

const MAX_COLUMNS = 10;

export function ColumnsModalManager({
  properties,
  onSave,
  defaultValue,
  hasConfigurationPage,
}) {
  const [visible, toggleVisible] = useToggle(false);
  const [columns, setColumns] = useState([]);
  const handleChange = ({ value, label }) => {
    setColumns(currSelecteds => [...currSelecteds, { label, name: value }]);
  };

  useEffect(() => {
    setColumns(defaultValue || []);
  }, [defaultValue]);

  const handleMoveItem = useCallback(
    (dragIndex, hoverIndex) => {
      setColumns(currSelecteds => {
        const newSelecteds = [...currSelecteds];
        [newSelecteds[dragIndex], newSelecteds[hoverIndex]] = [
          newSelecteds[hoverIndex],
          newSelecteds[dragIndex],
        ];
        return newSelecteds;
      });
    },
    [columns]
  );

  const handleRemoveItem = index => {
    setColumns(currSelecteds => {
      const newSelecteds = [...currSelecteds];
      newSelecteds.splice(index, 1);
      return newSelecteds;
    });
  };

  const handleClose = () => {
    toggleVisible();
    setColumns(defaultValue);
  };

  const filteredProperties = useMemo(
    () =>
      properties?.map(group => {
        return {
          ...group,
          options: group.options.filter(option => {
            return !columns.map(column => column.name).includes(option.value);
          }),
        };
      }),
    [columns, properties]
  );

  return (
    <>
      <SquareButton
        icon="columns"
        kind="secondary"
        onClick={toggleVisible}
        aria-label="open columns manager modal"
      />

      <Modal visible={visible} key="dialog" onClose={handleClose} size="s">
        <Heading>Manage columns</Heading>
        {columns.length < MAX_COLUMNS ? (
          <Select
            aria-label="select columns to add"
            onChange={handleChange}
            options={filteredProperties}
            portal
            placeholder="Select columns to add"
            value={{
              label: 'Select columns to add',
            }}
          />
        ) : (
          <WarningNotice>To change a column, first remove one.</WarningNotice>
        )}

        <DndProvider backend={HTML5Backend}>
          <PropertiesList>
            {columns.map(({ label }, i) => (
              <DraggableItem
                key={label}
                index={i}
                moveItem={handleMoveItem}
                canDrag={columns.length > 1}
                type="property"
              >
                <PropertyItem>
                  <PropertyName>
                    {columns.length > 1 && <Icon icon="grip-vertical" />}
                    {label}
                  </PropertyName>
                  {/* 
                    When the configuration page is disabled, the user can't remove 
                    the first column unless there are more than one column.
                   */}
                  {(hasConfigurationPage || columns.length > 1) && (
                    <IconButton
                      title="remove column"
                      aria-label="remove column"
                      icon="times"
                      kind="secondary"
                      onClick={() => handleRemoveItem(i)}
                    />
                  )}
                </PropertyItem>
              </DraggableItem>
            ))}
          </PropertiesList>
        </DndProvider>

        <ModalFooter>
          <Button kind="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave?.(columns.map(({ name }) => name));
              toggleVisible();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

ColumnsModalManager.propTypes = {
  onSave: PropTypes.func,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
      ),
      order: PropTypes.number,
    })
  ),
  hasConfigurationPage: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    properties: selectProfileAttributeDropdownOptions(state),
    hasConfigurationPage: selectAccountFeature(state, CONFIGURATION_PAGE),
  };
};

export default connect(mapStateToProps)(ColumnsModalManager);
