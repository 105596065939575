import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Profile from './Profile';
import { Audiences } from './Audiences';

export default () => (
  <Switch>
    {/* 
        These are placeholder routes for the audience,
        the components for the routes are currently under development 
        and once merged should be set as component prop in the routes bellow
     */}
    <Route path="/audience/users/:userId" component={Profile} />
    <Route path="/audience" component={Audiences} />
  </Switch>
);
