import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonLink, Icon, Link, Select } from '@studio/legacy-components';
import QueryResults from 'components/Common/QueryResults';
import MetricCard from 'components/Common/Metric/MetricCard';
import SelectGoal from 'components/Common/SelectGoal';
import CoolTip from 'components/Common/CoolTip';

import { selectGoalForRule } from 'reducers/account/rules';
import { update as updateRule } from 'actions/account/rules';
import { selectAccountGoal } from 'reducers/account/goals';

const SelectWrapper = styled.div`
  ${Select} {
    min-width: auto;
  }
`;

const PrefixIcon = styled(Icon)`
  margin-left: 4px;
`;

export const GoalCard = ({ goalName, onChange, goalId, onDelete, results }) => {
  const loaded = goalName ? !!results : true;
  const [{ flow_users = 0, goal_achievements = 0 }] =
    results && results.length > 0 ? results : [{}];
  return (
    <MetricCard
      loaded={loaded}
      name={
        <span aria-label="Goal Title" title={goalName || 'Goal Name'}>
          {goalName ? (
            <Link
              title="View Goal details in a new tab"
              href={`/goals/${goalId}`}
            >
              GOAL: {goalName}
            </Link>
          ) : (
            'GOAL MET'
          )}
          {goalName && (
            <ButtonLink
              aria-label="Change Goal"
              title="Change Goal"
              onClick={onDelete}
            >
              <PrefixIcon icon="times" />
            </ButtonLink>
          )}
        </span>
      }
      metric={
        goalName &&
        (flow_users
          ? Number.parseInt(100 * (goal_achievements / flow_users), 10)
          : '-')
      }
      metricType={goalName && flow_users && 'percentage'}
      caption={
        goalName && (
          <CoolTip tip="Goal met is the percentage of users who met the goal over a 7 day trailing time frame after seeing the flow">
            unique | {goal_achievements} users achieved goal
            <PrefixIcon icon="info-circle" />
          </CoolTip>
        )
      }
    >
      {!goalName && (
        <SelectWrapper>
          <SelectGoal onChange={onChange} />
        </SelectWrapper>
      )}
    </MetricCard>
  );
};

GoalCard.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      flow_users: PropTypes.number,
      goal_achievements: PropTypes.number,
    })
  ),
  goalName: PropTypes.string,
  goalId: PropTypes.string,
};

const GoalCardWithAnalytics = ({
  flowId,
  goalId,
  startTime,
  endTime,
  ...props
}) => {
  const query = goalId && {
    metrics: ['flow_users', 'goal_achievements'],
    conditions: [
      ['flow_id', '==', flowId],
      ['goal_id', '==', goalId],
    ],
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'Flow analytics',
        page: 'Flow analytics',
        component: 'GoalCard',
        description: 'Goal achievement card',
      },
    },
  };
  return (
    <QueryResults query={query}>
      {results => <GoalCard results={results} goalId={goalId} {...props} />}
    </QueryResults>
  );
};

GoalCardWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  goalId: PropTypes.string,
};

const mapStateToProps = (state, { flowId }) => {
  const goalId = selectGoalForRule(state, flowId);
  const { name: goalName } = selectAccountGoal(state, goalId) || {};
  return {
    flowId,
    goalId,
    goalName,
  };
};

const mapDispatchToProps = {
  updateRule,
};

const mergeProps = ({ flowId, goalId, goalName }, dispatchProps, ownProps) => ({
  ...ownProps,
  goalId,
  onChange: newGoalId =>
    dispatchProps.updateRule(flowId, { goals: [newGoalId] }),
  onDelete: () => dispatchProps.updateRule(flowId, { goals: [] }),
  goalName,
});

const ConnectedGoalCardWithAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GoalCardWithAnalytics);

ConnectedGoalCardWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ConnectedGoalCardWithAnalytics;
