import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import SelectIdentifierModal from './SelectIdentifierModal';

const StyledIcon = styled.span`
  margin-left: 10px;

  ${Icon} {
    color: var(--select-identifier-icon-color);
    cursor: pointer;
  }
`;

export const SelectIdentifierModalManager = ({ userId }) => {
  const [showModal, toggleShowModal] = useToggle();
  return (
    <>
      <SelectIdentifierModal
        visible={showModal}
        userId={userId}
        onClose={toggleShowModal}
      />
      <StyledIcon onClick={toggleShowModal}>
        {' '}
        <Icon icon="pen" />
      </StyledIcon>
    </>
  );
};

SelectIdentifierModalManager.propTypes = {
  userId: PropTypes.string,
};
