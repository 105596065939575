import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

import {
  FlowMetricGroup,
  FlowMetricCard,
  FlowMetricTooltip,
} from 'next/components/FlowMetricCard';
import MetricCard from 'components/Common/Metric/MetricCard';
import CoolTip from 'components/Common/CoolTip';

import { numberWithCommas } from 'utils/textFormatting';
import QueryResults from 'components/Common/QueryResults';
import GoalCard from './GoalCard';
import GoalMetricCard from './GoalMetricCard';

const InfoIcon = styled(Icon)`
  margin-left: 4px;
`;

export const Scoreboard = ({
  flowId,
  startTime,
  endTime,
  results,
  hasLookBackWindow,
}) => {
  const [
    {
      flow_users = 0,
      flows_shown = 0,
      flow_completed_users = 0,
      flows_completed = 0,
      flow_error_users = 0,
      flow_errors = 0,
    },
  ] = results && results.length > 0 ? results : [{}];

  const completionMetric = flow_users
    ? `${Number.parseInt(100 * (flow_completed_users / flow_users), 10)}%`
    : '-';
  const descriptionText = flows_shown
    ? `unique | ${Math.floor(100 * (flows_completed / flows_shown))}% total`
    : null;

  return hasLookBackWindow ? (
    <FlowMetricGroup>
      <FlowMetricCard
        loading={!results}
        title="Users"
        metric={flow_users}
        description={`unique | ${numberWithCommas(flows_shown)} total`}
      />
      <FlowMetricCard
        loading={!results}
        title="Completion"
        metric={completionMetric}
        description={descriptionText}
      />

      <FlowMetricCard
        loading={!results}
        title="Issues"
        metric={flow_error_users}
        additionalInfo={
          <FlowMetricTooltip>
            When Appcues cannot display a step, due to a missing or incorrect
            selector.
          </FlowMetricTooltip>
        }
        description={`unique | ${numberWithCommas(flow_errors)} total`}
      />

      <GoalMetricCard flowId={flowId} />
    </FlowMetricGroup>
  ) : (
    <Grid
      marginTop="1em"
      minItemWidths={[245, [1430, 200], [1250, 185], [1190, 260], [920, 185]]}
    >
      <MetricCard
        loaded={!!results}
        name="USERS"
        metric={flow_users}
        caption={`unique | ${numberWithCommas(flows_shown)} total`}
      />
      <MetricCard
        loaded={!!results}
        name="COMPLETION"
        metric={
          flow_users
            ? Number.parseInt(100 * (flow_completed_users / flow_users), 10)
            : '-'
        }
        metricType={flow_users && 'percentage'}
        caption={
          flows_shown
            ? `unique | ${
                flows_shown
                  ? `${Math.floor(
                      100 * (flows_completed / flows_shown)
                    )}% total`
                  : '-'
              }`
            : null
        }
      />
      <MetricCard
        loaded={!!results}
        name="ISSUES"
        metric={flow_error_users}
        caption={
          <CoolTip tip="When Appcues cannot display a step, due to a missing or incorrect selector.">
            unique | {numberWithCommas(flow_errors)} total
            <InfoIcon icon="info-circle" />
          </CoolTip>
        }
      />

      <GoalCard flowId={flowId} startTime={startTime} endTime={endTime} />
    </Grid>
  );
};

Scoreboard.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      flow_users: PropTypes.number,
      flows_shown: PropTypes.number,
      flow_completed_users: PropTypes.number,
      flows_completed: PropTypes.number,
      flow_error_users: PropTypes.number,
      flow_errors: PropTypes.number,
    })
  ),
};

const ScoreboardWithAnalytics = ({
  flowId,
  startTime,
  endTime,
  segmentId,
  hasLookBackWindow,
}) => (
  <QueryResults
    query={{
      metrics: [
        'flow_users',
        'flows_shown',
        'flow_completed_users',
        'flows_completed',
        'flow_error_users',
        'flow_errors',
      ],
      conditions: [
        ['flow_id', '==', flowId],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Flow analytics',
          page: 'Flow analytics',
          component: 'Scoreboard',
          description: 'Metric scoreboard for individual flow',
        },
      },
    }}
  >
    {results => (
      <Scoreboard
        results={results}
        flowId={flowId}
        startTime={startTime}
        endTime={endTime}
        hasLookBackWindow={hasLookBackWindow}
      />
    )}
  </QueryResults>
);

ScoreboardWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  segmentId: PropTypes.string,
};

export default ScoreboardWithAnalytics;
