import {
  takeEvery,
  put,
  select,
  take,
  call,
  getContext,
} from 'redux-saga/effects';

import {
  FETCH_CHECKLIST_ANALYTICS,
  FETCH_CHECKLIST_ANALYTICS_ERROR,
  FETCH_CHECKLIST_ANALYTICS_SUCCESS,
  FETCH_FLOW_LIST_ANALYTICS,
  FETCH_FLOW_LIST_ANALYTICS_ERROR,
  FETCH_FLOW_LIST_ANALYTICS_SUCCESS,
  FETCH_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS,
  FETCH_GOAL_REACHED_USER_SAMPLE,
  FETCH_GOALS_FIRST_REACHED_THIS_WEEK,
  FETCH_FLOW_PERFORMANCE_REPORT_DATA,
  FETCH_FLOW_ERROR_REPORT_DATA,
  FETCH_GOALS_REACHED_FROM_FLOW_STARTED,
} from 'constants/analytics/actionTypes';
import { selectAccountId } from 'reducers/account/meta';
import { patterns as rulesPatterns } from 'actions/account/rules';
import { reportError } from 'helpers/error-reporting';
import {
  setGoalsReachedByWeeklyAccountUsersError,
  setGoalsReachedByWeeklyAccountUsersFetching,
  setGoalsReachedByWeeklyAccountUsersSuccess,
  setGoalReachedUserSampleError,
  setGoalReachedUserSampleFetching,
  setGoalReachedUserSampleSuccess,
  setGoalsFirstReachedThisWeekFetching,
  setGoalsFirstReachedThisWeekSuccess,
  setGoalsFirstReachedThisWeekError,
  setFlowPerformanceReportDataFetching,
  setFlowPerformanceReportDataSuccess,
  setFlowPerformanceReportDataError,
  setFlowErrorReportDataFetching,
  setFlowErrorReportDataSuccess,
  setFlowErrorReportDataError,
  setGoalsReachedFromFlowStartedFetching,
  setGoalsReachedFromFlowStartedSuccess,
  setGoalsReachedFromFlowStartedError,
} from 'actions/analytics';
import {
  selectGoalsReachedByWeeklyAccountUsers,
  selectGoalReachedUserSample,
  selectGoalsFirstReachedThisWeek,
  selectFlowPerformanceReportData,
  selectFlowErrorReportData,
} from 'reducers/analytics';
import {
  selectAccountRulesSynced,
  selectAccountRules,
} from 'reducers/account/rules';

function fetchChecklistAnalyticsFailed(error, payload) {
  return put({
    type: FETCH_CHECKLIST_ANALYTICS_ERROR,
    error,
    payload,
  });
}

function fetchFlowListAnalyticsFailed(error, payload) {
  return put({
    type: FETCH_FLOW_LIST_ANALYTICS_ERROR,
    error,
    payload,
  });
}

function* fetchChecklistAnalytics(action) {
  const { payload } = action;
  const { checklistId } = payload;
  try {
    const analytics = yield getContext('analytics');
    const results = yield call(analytics.getChecklistStats, checklistId);

    yield put({
      type: FETCH_CHECKLIST_ANALYTICS_SUCCESS,
      payload: {
        ...payload,
        results,
      },
    });
  } catch (error) {
    yield put(fetchChecklistAnalyticsFailed(error, payload));
    yield call(reportError, error);
  }
}

function* fetchFlowListAnalytics() {
  const accountId = yield select(selectAccountId);

  try {
    const analytics = yield getContext('analytics');
    const results = yield call(analytics.getFlowListStats);

    yield put({
      type: FETCH_FLOW_LIST_ANALYTICS_SUCCESS,
      payload: {
        accountId,
        results,
      },
    });
  } catch (error) {
    yield put(fetchFlowListAnalyticsFailed(error, { accountId }));
    yield call(reportError, error);
  }
}

/**
 * Fetches aggregate goal reached stats from the Analytics API once per session
 * and populates the Analytics reducer with the result.
 *
 * @returns {IterableIterator<*>}
 */

export function* fetchGoalsReachedByWeeklyAccountUsers(/* action */) {
  try {
    const goalsReachedByWeeklyAccountUsers = yield select(
      selectGoalsReachedByWeeklyAccountUsers
    );

    const {
      meta: { synced, fetching },
    } = goalsReachedByWeeklyAccountUsers;

    if (!synced && !fetching) {
      const analytics = yield getContext('analytics');

      yield put(setGoalsReachedByWeeklyAccountUsersFetching());

      const results = yield call(analytics.getGoalsReachedByWeeklyAccountUsers);

      yield put(setGoalsReachedByWeeklyAccountUsersSuccess(results));
    }
  } catch (error) {
    yield put(setGoalsReachedByWeeklyAccountUsersError(error));
    yield call(reportError, error);
  }
}

export function* fetchGoalReachedUserSample(action) {
  const { goalId } = action.payload;

  try {
    if (!goalId) {
      throw new Error('no goal id provided to fetch goal reached user sample');
    }

    const goalReachedUserSample = yield select(
      selectGoalReachedUserSample,
      goalId
    );

    const {
      meta: { synced, fetching },
    } = goalReachedUserSample;

    if (!synced && !fetching) {
      const analytics = yield getContext('analytics');

      yield put(setGoalReachedUserSampleFetching(goalId));

      const results = yield call(analytics.getGoalReachedUserSample, goalId);

      yield put(setGoalReachedUserSampleSuccess(goalId, results));
    }
  } catch (error) {
    yield put(setGoalReachedUserSampleError(goalId, error));
    yield call(reportError, error);
  }
}

/**
 * Fetches aggregate "goal first reached this week" stats from the Analytics API
 * once per session and populates the Analytics reducer with the result.
 *
 * @returns {IterableIterator<*>}
 */

export function* fetchGoalsFirstReachedThisWeek(/* action */) {
  try {
    const goalsFirstReachedThisWeek = yield select(
      selectGoalsFirstReachedThisWeek
    );

    const {
      meta: { synced, fetching },
    } = goalsFirstReachedThisWeek;

    if (!synced && !fetching) {
      const analytics = yield getContext('analytics');

      yield put(setGoalsFirstReachedThisWeekFetching());

      const results = yield call(analytics.getGoalsFirstReachedThisWeek);

      yield put(setGoalsFirstReachedThisWeekSuccess(results));
    }
  } catch (error) {
    yield put(setGoalsFirstReachedThisWeekError(error));
    yield call(reportError, error);
  }
}

export function* fetchGoalsReachedFromFlowStarted(action) {
  const { accountId, goalId, flowId, conversionWindow } = action.payload;

  try {
    if (accountId && goalId && flowId) {
      const analytics = yield getContext('analytics');

      yield put(
        setGoalsReachedFromFlowStartedFetching({
          accountId,
          goalId,
          flowId,
          conversionWindow,
        })
      );

      const results = yield call(analytics.getGoalsReachedFromFlowStarted, {
        accountId,
        goalId,
        flowId,
        conversionWindow,
      });

      yield put(setGoalsReachedFromFlowStartedSuccess(results));
    }
  } catch (error) {
    yield put(setGoalsReachedFromFlowStartedError(error));
    yield call(reportError, error);
  }
}

export function* fetchFlowErrorReportData() {
  try {
    const flowErrorReportData = yield select(selectFlowErrorReportData);

    const {
      meta: { fetching, errored, synced },
    } = flowErrorReportData;

    if (errored || (!synced && !fetching)) {
      const analytics = yield getContext('analytics');

      yield put(setFlowErrorReportDataFetching());

      const results = yield call(analytics.getFlowErrorReportData);

      yield put(setFlowErrorReportDataSuccess(results));
    }
  } catch (error) {
    yield put(setFlowErrorReportDataError(error));
    yield call(reportError, error);
  }
}

export function* fetchFlowPerformanceReportData() {
  try {
    const flowPerformanceReportData = yield select(
      selectFlowPerformanceReportData
    );

    const {
      meta: { fetching, errored, synced },
    } = flowPerformanceReportData;

    if (errored || (!synced && !fetching)) {
      const accountRulesSynced = yield select(selectAccountRulesSynced);
      const analytics = yield getContext('analytics');

      if (!accountRulesSynced) {
        yield take(rulesPatterns.resolve);
      }

      const rules = yield select(selectAccountRules);

      // analytics API fails if request is empty
      const rulesWithGoals = [{}];

      Object.keys(rules).forEach(rule => {
        if (rules[rule].goals) {
          rulesWithGoals.push({
            flow_id: rules[rule].id,
            goal_id: rules[rule].goals[0],
          });
        }
      });

      yield put(setFlowPerformanceReportDataFetching());

      const flowGoals = {
        flow_goals: rulesWithGoals,
      };

      const results = yield call(
        analytics.getFlowPerformanceReportData,
        flowGoals
      );

      yield put(setFlowPerformanceReportDataSuccess(rulesWithGoals, results));
    }
  } catch (error) {
    yield put(setFlowPerformanceReportDataError(error));
    yield call(reportError, error);
  }
}

export default function* analyticsSagas() {
  yield takeEvery(FETCH_CHECKLIST_ANALYTICS, fetchChecklistAnalytics);
  yield takeEvery(FETCH_FLOW_LIST_ANALYTICS, fetchFlowListAnalytics);
  yield takeEvery(
    FETCH_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS,
    fetchGoalsReachedByWeeklyAccountUsers
  );
  yield takeEvery(FETCH_GOAL_REACHED_USER_SAMPLE, fetchGoalReachedUserSample);
  yield takeEvery(
    FETCH_GOALS_FIRST_REACHED_THIS_WEEK,
    fetchGoalsFirstReachedThisWeek
  );
  yield takeEvery(
    FETCH_GOALS_REACHED_FROM_FLOW_STARTED,
    fetchGoalsReachedFromFlowStarted
  );
  yield takeEvery(
    FETCH_FLOW_PERFORMANCE_REPORT_DATA,
    fetchFlowPerformanceReportData
  );
  yield takeEvery(FETCH_FLOW_ERROR_REPORT_DATA, fetchFlowErrorReportData);
}
