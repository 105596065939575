import React from 'react';
import { Link } from '@appcues/sonar';
import { Link as LegacyLink } from '@studio/legacy-components';

export const UserIdentifierCell = ({
  userId,
  properties,
  userProfileIdentifier,
}) => {
  let identifierValue = userId;
  if (userProfileIdentifier) {
    identifierValue = properties?.[userProfileIdentifier];
  }

  return (
    <Link forwardedAs={LegacyLink} to={`/users/${encodeURIComponent(userId)}`}>
      {identifierValue ?? '-'}
    </Link>
  );
};
