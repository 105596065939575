import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from '@studio/legacy-components';
import MetricCard from 'next/components/MetricCard';
import {
  queryExperienceGoal,
  queryExperienceUsers,
  queryGoalReachedFromFlowStarted,
} from 'next/client/queries';
import {
  selectExperienceUsersAnalytics,
  readAnalytics,
  selectExperienceGoalAnalytics,
  readGoalsReachedFromFlowStarted,
  selectGoalReachedFormFlowStarted,
} from 'next/entities/analytics';
import {
  FlowMetricCard,
  FlowMetricGoalPopover,
  FlowMetricTooltip,
  FlowMetricGroup,
} from 'next/components/FlowMetricCard';
import { GOAL_LOOK_BACK_WINDOW, selectFeature } from 'next/entities/features';
import { GoalShape, selectGoal } from 'next/entities/goals';
import { OverviewMetricsContainer } from './styled';

const calculatePercentage = (numerator, denominator) => {
  const result = numerator / denominator;

  if (denominator === 0 || !Number.isFinite(result)) {
    return '0%';
  }

  return `${Math.floor(result * 100)}%`;
};

export function OverviewMetrics({
  id,
  onLoad,
  usersAnalytics,
  goal,
  goalAnalytics,
  hasLookBackWindow,
  goalConversionWindowDays,
  goalsReached,
}) {
  const loadingUsersAnalytics = !usersAnalytics;
  const loadingGoalAnalytics = !goalAnalytics;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const { usersMetrics, usersCompletedMetrics } = useMemo(() => {
    const [userQueryResult] = usersAnalytics || [];

    if (!userQueryResult) {
      const emptyMetric = {
        metric: '-',
        caption: null,
      };

      return {
        usersMetrics: emptyMetric,
        usersCompletedMetrics: emptyMetric,
      };
    }

    const {
      experience_completed_users: completedUsers,
      experience_users: users,
      experiences_completed: completed,
      experiences_shown: shown,
    } = userQueryResult;

    return {
      usersMetrics: {
        metric: users.toLocaleString(),
        caption: `${shown.toLocaleString()} total ${pluralize(
          'time',
          shown
        )} shown`,
      },
      usersCompletedMetrics: {
        metric: calculatePercentage(completedUsers, users),
        caption: `${calculatePercentage(
          completed,
          shown
        )} of total users completed`,
      },
    };
  }, [usersAnalytics]);

  const goalMetrics = useMemo(() => {
    if (hasLookBackWindow) {
      if (!goal) {
        return {
          loading: false,
          metric: '-',
          caption: 'No goal set',
          additionalInfo: <FlowMetricGoalPopover flowId={id} />,
        };
      }

      const metrics =
        goalsReached?.find(item => item.flow_version === 'all-time') || {};

      const reached = metrics?.goal_reached ?? 0;
      const conversion = metrics?.conversion_rate ?? 0;

      return {
        loading: !goalsReached,
        metric: `${Number.parseInt(100 * conversion, 10)}%`,
        additionalInfo: (
          <FlowMetricTooltip>
            Users who met the goal within {goalConversionWindowDays} days after
            seeing the flow,
          </FlowMetricTooltip>
        ),
        caption: (
          <>
            {reached} unique users achieved
            <Link to={`/goals/${goal?.id}`}> {goal?.name}</Link>
          </>
        ),
      };
    }

    const goalQueryResult = (goalAnalytics || []).find(
      analytics => analytics.experience_id === id
    );

    if (!goal || !goalQueryResult) {
      return {
        metric: '-',
        caption: null,
      };
    }

    const { experience_users: users, goal_achievements: achievements } =
      goalQueryResult;

    return {
      metric: calculatePercentage(achievements, users),
      caption: (
        <>
          {achievements} unique users achieved
          <Link to={`/goals/${goal.id}`}> {goal.name}</Link>
        </>
      ),
    };
  }, [
    goalAnalytics,
    goal,
    id,
    hasLookBackWindow,
    goalsReached,
    goalConversionWindowDays,
  ]);

  return hasLookBackWindow ? (
    <FlowMetricGroup columns={3}>
      <FlowMetricCard
        title="Unique Users"
        metric={usersMetrics.metric}
        description={usersMetrics.caption}
        additionalInfo={
          <FlowMetricTooltip>
            Understand your Flow's reach. This Flow was shown to these unique
            users over the selected time period.
          </FlowMetricTooltip>
        }
        loading={loadingUsersAnalytics}
      />

      <FlowMetricCard
        title="Unique Users Completed"
        metric={usersCompletedMetrics.metric}
        description={usersCompletedMetrics.caption}
        loading={loadingUsersAnalytics}
      />

      <FlowMetricCard
        title="Goal"
        metric={goalMetrics.metric}
        description={goalMetrics.caption}
        additionalInfo={goalMetrics.additionalInfo}
        loading={loadingGoalAnalytics}
      />
    </FlowMetricGroup>
  ) : (
    <OverviewMetricsContainer>
      <MetricCard
        title="Unique Users"
        metric={usersMetrics.metric}
        caption={usersMetrics.caption}
        tooltip="Understand your Flow's reach. This Flow was shown to these unique users over the selected time period."
        loading={loadingUsersAnalytics}
      />
      <MetricCard
        title="Unique Users Completed"
        metric={usersCompletedMetrics.metric}
        caption={usersCompletedMetrics.caption}
        loading={loadingUsersAnalytics}
      />
      <MetricCard
        title="Goal"
        metric={goalMetrics.metric}
        caption={goalMetrics.caption}
        tooltip="Understand how this Flow is helping you achieve an Appcues Goal during the selected time period."
        loading={loadingGoalAnalytics}
      />
    </OverviewMetricsContainer>
  );
}

OverviewMetrics.propTypes = {
  id: PropTypes.string,
  onLoad: PropTypes.func,
  usersAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      experience_completed_users: PropTypes.number,
      users: PropTypes.number,
      experiences_completed: PropTypes.number,
      experiences_shown: PropTypes.number,
    })
  ),
  goalAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      experience_completed_users: PropTypes.number,
      users: PropTypes.number,
      experiences_completed: PropTypes.number,
      experiences_shown: PropTypes.number,
    })
  ),
  goal: GoalShape,
  hasLookBackWindow: PropTypes.bool,
  goalConversionWindowDays: PropTypes.number,
  goalsReached: PropTypes.arrayOf({
    flow_version: PropTypes.string,
    flow_started: PropTypes.number,
    goal_reached: PropTypes.number,
    conversion_rate: PropTypes.number,
  }),
};

const getQueryParams = ({ id, startTime, endTime, segmentId, goalId }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
  goalId,
});

const getGolsParams = ({ accountId, rule, id, goalId }) => ({
  conversionWindow: rule?.goalConversionWindowDays,
  accountId,
  flowId: id,
  goalId,
});

const mapStateToProps = (state, ownProps) => {
  const { goalId } = ownProps;
  const queryParams = getQueryParams(ownProps);
  const goal = selectGoal(state, goalId);
  const goalsParams = getGolsParams(ownProps);

  return {
    goalConversionWindowDays: ownProps?.rule?.goalConversionWindowDays,
    usersAnalytics: selectExperienceUsersAnalytics(state, queryParams),
    hasLookBackWindow: selectFeature(state, GOAL_LOOK_BACK_WINDOW),
    goalsReached: selectGoalReachedFormFlowStarted(state, goalsParams),
    goalAnalytics: goalId
      ? selectExperienceGoalAnalytics(state, queryParams)
      : [],
    goal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const usersQuery = queryExperienceUsers(queryParams);
  const goalQuery = queryExperienceGoal(queryParams);
  const goalsParams = getGolsParams(ownProps);

  const goalReached = queryGoalReachedFromFlowStarted(goalsParams);

  return {
    onLoad: () => {
      dispatch(readAnalytics(usersQuery));

      /* Only fetch goal metrics if goal is set */
      if (queryParams.goalId) {
        dispatch(readAnalytics(goalQuery));
        dispatch(readGoalsReachedFromFlowStarted(goalReached));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMetrics);
