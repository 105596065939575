import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';

const TYPE = 'goals';

export default createCollectionReducer(TYPE);

export const selectAccountGoals = state => state.account.goals;
export const selectAccountGoal = (state, goalId) =>
  selectAccountGoals(state)[goalId];
export const selectAccountGoalsSynced = state => selectIsSynced(state, TYPE);
