import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  ExpandableText,
  Link,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  PanelFooter,
} from '@studio/legacy-components';
import { ResultsWrapper } from 'components/Insights/Common/Styled';
import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';
import SelectedEventLabel from 'components/Insights/Explorer/Chart/SelectedEventLabel';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';
import { selectAccountFeature } from 'reducers/account/features';
import { USER_PROFILE } from 'constants/features';
import UserProfileModalManager from './UserProfileModalManager';
import { UITable } from './styled';

export const Table = ({
  source,
  results,
  isEmpty,
  currentPage,
  totalResults,
  pageSize,
  orderByColumn,
  orderByDirection,
  onSort,
  onChangePage,
  hasUserProfile,
}) => (
  <>
    <ResultsWrapper>
      <TableOverlay isEmpty={isEmpty} results={results} type="events-by-user" />
      <UITable>
        <Thead>
          <Tr>
            <Th>User ID</Th>
            <Th>Event Name</Th>
            {source !== 'mobile' && (
              <Th
                sortable
                sorted={
                  orderByColumn === 'a:_identity:_currentPageUrl' &&
                  orderByDirection
                }
                onClick={() => onSort('a:_identity:_currentPageUrl')}
              >
                URL
              </Th>
            )}
            <Th
              sortable
              sorted={orderByColumn === 'timestamp' && orderByDirection}
              onClick={() => onSort('timestamp')}
            >
              Last Triggered
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {(results || []).map(
            (
              {
                user_id,
                name,
                flow_id,
                checklist_id,
                goal_id,
                'a:_identity:_currentPageUrl': url,
                timestamp,
                'a:_identity': profile,
              },
              index
            ) => (
              // we don't have any unique data that can be used to identify a user, so we just use the index
              // eslint-disable-next-line react/no-array-index-key
              <Tr key={`${timestamp}${index}`}>
                <Td>
                  {hasUserProfile ? (
                    <Link to={`/users/${encodeURIComponent(user_id)}`}>
                      {user_id}
                    </Link>
                  ) : (
                    <UserProfileModalManager
                      userId={user_id}
                      identity={profile}
                      timestamp={timestamp}
                    >
                      {user_id}
                    </UserProfileModalManager>
                  )}
                </Td>
                <Td>
                  <SelectedEventLabel
                    event={name}
                    id={flow_id || checklist_id || goal_id}
                    source={source}
                  />
                </Td>
                {source !== 'mobile' && (
                  <Td>
                    {url ? (
                      <ExpandableText lines={2}>{url}</ExpandableText>
                    ) : (
                      '-'
                    )}
                  </Td>
                )}
                <Td>{moment(timestamp).format('lll')}</Td>
              </Tr>
            )
          )}
        </Tbody>
      </UITable>
    </ResultsWrapper>
    {results && results.length > 0 && (
      <PanelFooter>
        <Pagination
          pageCount={Math.ceil(totalResults / pageSize)}
          currentIndex={currentPage}
          onChange={onChangePage}
        />
        <PaginationMessage
          currentIndex={currentPage}
          pageSize={pageSize}
          total={totalResults}
        />
      </PanelFooter>
    )}
  </>
);

Table.propTypes = {
  source: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      name: PropTypes.string,
      flow_id: PropTypes.string,
      checklist_id: PropTypes.string,
      'a:_identity:_currentPageUrl': PropTypes.string,
      timestamp: PropTypes.string,
      'a:_identity': PropTypes.objectOf(PropTypes.string),
    })
  ),
  currentPage: PropTypes.number,
  totalResults: PropTypes.number,
  pageSize: PropTypes.number,
  orderByColumn: PropTypes.string,
  orderByDirection: PropTypes.string,
  onSort: PropTypes.func,
  onChangePage: PropTypes.func,
  hasUserProfile: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasUserProfile: selectAccountFeature(state, USER_PROFILE),
});

export default connect(mapStateToProps)(Table);
