import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  UpsellNavItem,
  Logo,
  Wrapper,
  Header,
  Divider,
  Scrollable,
  Links,
  Group,
  CallToActions,
  BannerIcon,
} from '@studio/legacy-navigation';
import { Link } from 'react-router-dom';
import {
  PINS,
  PINS_BETA,
  selectFeature,
  EXPERIMENTS,
  USER_LISTS_SEGMENTS,
  JOURNEYS,
  BANNERS,
  LAUNCHPADS_V2,
  WORKFLOWS,
} from 'next/entities/features';
import { selectUser } from 'next/entities/user';
import { selectTrialStatus } from 'next/entities/trial-status';

import AccountMenu from './AccountMenu';
import HelpMenu from './HelpMenu';
import InstallButton from './InstallButton';
import SpoofControl from './SpoofControl';
import NavItem from './NavItem';

export const LegacyNavigation = ({
  user,
  hasUserListsSegments,
  hasPinsBeta,
  hasPins,
  hasExperiments,
  hasJourneys,
  hasBanners,
  hasInstalled,
  hasLaunchpads,
  hasWorkflows,
}) => (
  <Wrapper>
    <SpoofControl />

    <Header>
      <Link to="/">
        <Logo />
      </Link>
    </Header>

    <Scrollable>
      <Links>
        <Group>
          <NavItem exact icon="th-list" label="Overview" to="/" />
          <NavItem icon="map" label="Flows" to="/flows" />
          {hasPinsBeta &&
            (hasPins ? (
              <NavItem icon="thumbtack" label="Pins" to="/pins" />
            ) : (
              <UpsellNavItem
                user={user}
                aria-label="Upgrade to get access to Pin experiences."
                data-ctt="Clicked disabled Pins link"
                icon="thumbtack"
                label="Pins"
                message="I'd like to upgrade to enable Pin experiences."
                subject="Interested in Pins"
              />
            ))}
          {hasBanners && (
            <NavItem icon={<BannerIcon />} label="Banners" to="/banners" />
          )}
          <NavItem icon="check-circle" label="Checklists" to="/checklists" />
          {hasLaunchpads && (
            <NavItem
              icon="fa-rocket"
              label="Launchpads"
              to="/launchpads"
              external
            />
          )}
          <NavItem icon="comment-alt" label="NPS" to="/nps" />
        </Group>

        <Divider />

        <Group>
          {hasWorkflows && (
            <NavItem
              icon="arrows-turn-to-dots"
              label="Workflows"
              to="/workflows"
            />
          )}

          {hasExperiments && (
            <NavItem
              icon="flask"
              label="Experiments"
              to="/experiments"
              external
            />
          )}
          <NavItem
            icon="chart-bar"
            label="Events Explorer"
            to="/insights/explorer"
          />
          <NavItem icon="th" label="Saved Charts" to="/insights/charts" />
          {hasJourneys && (
            <NavItem
              icon="project-diagram"
              label="Journeys"
              to="/journeys"
              external
            />
          )}
        </Group>

        <Divider />

        <Group>
          <NavItem icon="palette" label="Themes" to="/themes" />

          {hasUserListsSegments ? (
            <NavItem icon="users" label="Audiences" to="/audience/segments" />
          ) : (
            <NavItem icon="users" label="Audiences" to="/segments" />
          )}

          <NavItem icon="bullseye" label="Goals" to="/goals" />
        </Group>

        <Divider />

        <Group>
          <HelpMenu />
        </Group>
      </Links>

      {!hasInstalled ? (
        <CallToActions>
          <InstallButton />
        </CallToActions>
      ) : null}
    </Scrollable>

    <Divider />

    <AccountMenu />
  </Wrapper>
);

LegacyNavigation.propTypes = {
  user: PropTypes.shape({
    fullname: PropTypes.string,
    email: PropTypes.string,
  }),
  hasPins: PropTypes.bool,
  hasPinsBeta: PropTypes.bool,
  hasUserListsSegments: PropTypes.bool,
  hasExperiments: PropTypes.bool,
  hasJourneys: PropTypes.bool,
  hasBanners: PropTypes.bool,
  hasInstalled: PropTypes.bool,
  hasLaunchpads: PropTypes.bool,
  hasWorkflows: PropTypes.bool,
};

const mapStateToProps = state => {
  const trialStatus = selectTrialStatus(state);
  const { isWebInstalled, isMobileInstalled } = trialStatus;
  const hasInstalled = Boolean(isWebInstalled) || Boolean(isMobileInstalled);

  return {
    user: selectUser(state),
    hasPins: selectFeature(state, PINS),
    hasPinsBeta: selectFeature(state, PINS_BETA),
    hasUserListsSegments: selectFeature(state, USER_LISTS_SEGMENTS),
    hasExperiments: selectFeature(state, EXPERIMENTS),
    hasJourneys: selectFeature(state, JOURNEYS),
    hasBanners: selectFeature(state, BANNERS),
    hasInstalled,
    hasLaunchpads: selectFeature(state, LAUNCHPADS_V2),
    hasWorkflows: selectFeature(state, WORKFLOWS),
  };
};

export default connect(mapStateToProps)(LegacyNavigation);
