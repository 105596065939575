import { put, getContext, takeEvery, call } from 'redux-saga/effects';
import {
  EXPERIENCE_PUBLISHED,
  EXPERIENCE_UNPUBLISHED,
} from 'next/entities/experiences';
import { INITIALIZE } from 'next/root/actions';
import { resolve, reject } from './actions';

export const normalize = results => {
  return results.reduce((acc, next) => {
    acc[next.name] = next;
    return acc;
  }, {});
};

function* fetchEntitlements() {
  try {
    const api = yield getContext('api');
    const results = yield call(api.getEntitlements);
    yield put(resolve(normalize(results)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, fetchEntitlements);
  yield takeEvery(EXPERIENCE_PUBLISHED, fetchEntitlements);
  yield takeEvery(EXPERIENCE_UNPUBLISHED, fetchEntitlements);
}
