import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Modal,
  Select,
  Button,
  ButtonGroup,
  Overflow,
  Heading,
  NegativeNotice,
  Description,
} from '@studio/legacy-components';
import { asPromised } from 'utils/as-promised';
import { updateAccountMeta } from 'actions/account/meta';
import { selectProfileAttributeDropdownOptions } from 'selectors/profile-attributes';
import { selectAccountMeta } from 'reducers/account/meta';
import IdentifierOption from './IdentifierOption';

const SubHeader = styled(Description)`
  margin-bottom: 20px;
`;

const ButtonGroupWrap = styled(ButtonGroup)`
  margin-top: 35px;
`;

export const SelectIdentifierModal = ({
  visible = true,
  onClose,
  currentIdentifier,
  dropdownOptions = [],
  onSelectIdentifier,
}) => {
  const [selectedIdentifier, setSelectedIdentifier] =
    useState(currentIdentifier);
  const [inFlight, setInFlight] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSelectedIdentifier(currentIdentifier);
  }, [currentIdentifier, setSelectedIdentifier]);

  const handleSubmit = async () => {
    setInFlight(true);
    if (error) setError(false);

    try {
      await onSelectIdentifier(selectedIdentifier);
      onClose();
    } catch {
      setError(true);
    } finally {
      setInFlight(false);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} size="m">
      <Overflow>
        <Heading>Edit page title</Heading>
        <SubHeader>Select a property to show on top of the page.</SubHeader>
        {error && !inFlight && (
          <>
            (
            <NegativeNotice>
              We&apos;re sorry, something went wrong on our end, try again
            </NegativeNotice>
            <br />)
          </>
        )}
        <Select
          onChange={selected => setSelectedIdentifier(selected?.value)}
          options={dropdownOptions}
          components={{ Option: IdentifierOption }}
          portal
          placeholder="Choose an Identifier"
          value={dropdownOptions
            .flatMap(current => current.options)
            .find(option => option.value === selectedIdentifier)}
        />
        <ButtonGroupWrap right>
          <Button
            kind="positive"
            disabled={
              !selectedIdentifier ||
              inFlight ||
              selectedIdentifier === currentIdentifier
            }
            onClick={handleSubmit}
          >
            {inFlight ? 'Applying...' : 'Apply'}
          </Button>
          <Button kind="secondary" onClick={onClose} disabled={inFlight}>
            Cancel
          </Button>
        </ButtonGroupWrap>
      </Overflow>
    </Modal>
  );
};

SelectIdentifierModal.propTypes = {
  visible: PropTypes.bool,
  currentIdentifier: PropTypes.string,
  onClose: PropTypes.func,
  onSelectIdentifier: PropTypes.func,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
};

const mapStateToProps = state => ({
  currentIdentifier: selectAccountMeta(state)?.userProfileIdentifier,
  dropdownOptions: selectProfileAttributeDropdownOptions(state),
});

const mapDispatchToProps = dispatch => ({
  onSelectIdentifier: value =>
    asPromised(dispatch, updateAccountMeta({ userProfileIdentifier: value })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectIdentifierModal);
