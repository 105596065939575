import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SettingsNavigation } from '@studio/navigation';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { selectUser } from 'next/entities/user';
import { selectSpoofing, start as startSpoofing } from 'next/entities/spoofing';
import {
  CONFIGURATION_PAGE,
  EMAIL_WORKFLOWS,
  FLOW_LOCALIZATION,
  INTEGRATION_LISTING_PAGE_REDESIGN,
  MOBILE_FLOWS,
  selectFeature,
} from 'next/entities/features';
// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';
import { logoutUserFromChromeExtension } from 'actions/chromeExtension';

const SettingsNavigationProvider = ({
  hasFlowLocalization,
  hasIntegrationPageRedesign,
  hasMobileFlows,
  hasEmailWorkflows,
  hasConfigurationPage,
  user,
  onSpoof,
  isSpoofing,
  logoutUserFromChromeExtension: logoutFromCrx,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClickLogout = () => {
    logoutFromCrx();
    authClient.logout();
  };

  const navigationFeatureFlags = useMemo(
    () => ({
      hasFlowLocalization,
      hasIntegrationPageRedesign,
      hasMobileFlows,
      hasEmailWorkflows,
      hasConfigurationPage,
    }),
    [
      hasFlowLocalization,
      hasIntegrationPageRedesign,
      hasMobileFlows,
      hasEmailWorkflows,
      hasConfigurationPage,
    ]
  );

  return (
    <SettingsNavigation
      NavLink={NavLink}
      featureFlags={navigationFeatureFlags}
      matchExactRoutes
      currentPath={pathname}
      onClickBackArrow={() => history.push('/')}
      user={user}
      onSpoof={onSpoof}
      isSpoofing={isSpoofing}
      onClickLogout={handleClickLogout}
    />
  );
};

SettingsNavigationProvider.propTypes = {
  hasFlowLocalization: PropTypes.bool,
  hasIntegrationPageRedesign: PropTypes.bool,
  hasMobileFlows: PropTypes.bool,
  hasEmailWorkflows: PropTypes.bool,
  hasConfigurationPage: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    fullname: PropTypes.string,
    email: PropTypes.string,
    isAdmin: PropTypes.bool,
  }),
  onSpoof: PropTypes.func,
  isSpoofing: PropTypes.bool,
  logoutUserFromChromeExtension: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    hasConfigurationPage: selectFeature(state, CONFIGURATION_PAGE),
    hasFlowLocalization: selectFeature(state, FLOW_LOCALIZATION),
    hasIntegrationPageRedesign: selectFeature(
      state,
      INTEGRATION_LISTING_PAGE_REDESIGN
    ),
    hasMobileFlows: selectFeature(state, MOBILE_FLOWS),
    hasEmailWorkflows: selectFeature(state, EMAIL_WORKFLOWS),
    user: selectUser(state),
    isSpoofing: selectSpoofing(state),
  };
};

const mapDispatchToProps = {
  onSpoof: startSpoofing,
  logoutUserFromChromeExtension,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsNavigationProvider);
