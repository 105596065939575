import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SETTINGS_NAV_2024, selectFeature } from 'next/entities/features';
import MainNavigation from './MainNavigation';
import LegacyNavigation from './LegacyNavigation';
import SettingsNavigationProvider from './SettingsNavigationProvider';

const NavigationProvider = ({ hasNewNavigation }) => {
  const { pathname } = useLocation();
  const isSettings = pathname.startsWith('/settings');
  const isIntegrations = pathname.startsWith('/integrations');

  if (hasNewNavigation && (isSettings || isIntegrations)) {
    return <SettingsNavigationProvider />;
  }

  if (hasNewNavigation) {
    return <MainNavigation />;
  }

  return <LegacyNavigation />;
};

NavigationProvider.propTypes = {
  hasNewNavigation: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    hasNewNavigation: selectFeature(state, SETTINGS_NAV_2024),
  };
};

export default connect(mapStateToProps)(NavigationProvider);
