import React from 'react';
import { connect } from 'react-redux';
import { TrialMeterByDays } from '@studio/legacy-navigation';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { Button } from '@appcues/sonar';
import { selectTrialStatus } from 'next/entities/trial-status';
import { selectAccount } from 'next/entities/account';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const mapStateToProps = state => {
  const account = selectAccount(state);
  const trialStatus = selectTrialStatus(state);
  return {
    account,
    trialStatus,
    Link: unhandledProps => (
      <Button variant="tertiary" forwardedAs={StyledLink} {...unhandledProps} />
    ),
  };
};

export default connect(mapStateToProps)(TrialMeterByDays);
