import React from 'react';
import PropTypes from 'prop-types';
import { Metric } from '@appcues/sonar';

function FlowMetricCard({
  title,
  metric,
  description,
  loading,
  additionalInfo = null,
}) {
  return (
    <Metric.Root type="section">
      <Metric.Header>
        <Metric.Title>{title}</Metric.Title>
        {additionalInfo}
      </Metric.Header>
      <Metric.Body description={description} isLoading={loading}>
        <Metric.CurrentValue>{metric ?? '-'}</Metric.CurrentValue>
      </Metric.Body>
    </Metric.Root>
  );
}

FlowMetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  metric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  loading: PropTypes.bool,
  additionalInfo: PropTypes.node,
};

export default FlowMetricCard;
