import { selectAccountAnalytics } from 'reducers/account/analytics-v2';
import { selectFlow } from 'reducers/account/flows';
import { selectAccountChecklist } from 'entities/checklists';
import { selectAccountGoal } from 'reducers/account/goals';
import {
  selectExperience,
  selectMobile,
  selectPin,
} from 'entities/experiences';

const STARTED_EVENTS = new Set([
  'appcues:flow_started',
  'appcues:checklist_shown',
  'appcues:v2:experience_started',
]);

const COMPLETED_EVENTS = new Set([
  'appcues:flow_completed',
  'appcues:checklist_completed',
  'appcues:nps_score',
  'appcues:nps_feedback',
  'appcues:goal_reached',
  'appcues:v2:experience_completed',
]);

const SKIPPED_EVENTS = new Set([
  'appcues:checklist_skipped',
  'appcues:v2:experience_dismissed',
]);

const FLOW_EVENTS = new Set(['appcues:flow_started', 'appcues:flow_completed']);

// Matches "mobile" or "mobile_embed"
const MOBILE_EXPERIENCE_TYPE_PREFIX = 'mobile';

const MOBILE_FLOW_EVENTS = new Set([
  'appcues:v2:experience_started',
  'appcues:v2:experience_completed',
  'appcues:v2:experience_dismissed',
  'appcues:v2:step_interaction',
  'appcues:v2:step_seen',
]);

const CHECKLIST_EVENTS = new Set([
  'appcues:checklist_shown',
  'appcues:checklist_completed',
  'appcues:checklist_skipped',
]);

const PIN_EXPERIENCE_TYPE = 'persistent';
const BANNER_EXPERIENCE_TYPE = 'banner';

const NPS_EVENTS = new Set(['appcues:nps_score', 'appcues:nps_feedback']);

const GOAL_EVENTS = new Set(['appcues:goal_reached']);

export const selectUserActivityDetails = (state, query) => {
  const results = selectAccountAnalytics(state, query);

  return results
    ?.map(
      ({
        name,
        flow_id,
        checklist_id,
        experience_id,
        experience_type,
        goal_id,
        step_id,
        'a:score': nps_score,
        'a:feedback': nps_feedback,
        timestamp,
      }) => {
        const isStartedEvent = STARTED_EVENTS.has(name);
        const isCompletedEvent = COMPLETED_EVENTS.has(name);
        const isSkippedEvent = SKIPPED_EVENTS.has(name);

        const isFlowEvent = FLOW_EVENTS.has(name);
        const isPinEvent = experience_type === PIN_EXPERIENCE_TYPE;
        const isBannerEvent = experience_type === BANNER_EXPERIENCE_TYPE;
        const isChecklistEvent = CHECKLIST_EVENTS.has(name);
        const isNpsEvent = NPS_EVENTS.has(name);
        const isGoalEvent = GOAL_EVENTS.has(name);
        const isBuilderEvent = /appcues_custom:/.test(name);
        const isMobileEvent =
          experience_type?.startsWith(MOBILE_EXPERIENCE_TYPE_PREFIX) &&
          MOBILE_FLOW_EVENTS.has(name);

        let action = '';

        switch (true) {
          case isStartedEvent:
            action = 'started';
            break;
          case isCompletedEvent:
            action = 'completed';
            break;
          case isSkippedEvent:
            action = 'skipped';
            break;
          default:
            action = 'triggered';
        }

        switch (true) {
          case isFlowEvent:
            return {
              action,
              activity: 'flow',
              displayName: selectFlow(state, flow_id)?.name,
              href: `/flows/${flow_id}/analytics`,
              timestamp,
            };
          case isMobileEvent:
            return {
              action,
              activity: 'flow',
              displayName: selectMobile(state, experience_id)?.name,
              href: `/mobile/flows/${experience_id}/analytics`,
              timestamp,
            };

          case isPinEvent:
            return {
              action,
              activity: 'pin',
              displayName: `${selectPin(state, experience_id)?.name}
              ${
                step_id === null
                  ? ''
                  : `, ${
                      selectPin(state, experience_id)?.steps.find(
                        ({ id }) => id === step_id
                      )?.name
                    }`
              }
              `,
              href: `/pins/${experience_id}/analytics`,
              timestamp,
            };

          case isBannerEvent:
            return {
              action,
              activity: 'banner',
              displayName: `${selectExperience(state, experience_id)?.name}
              `,
              href: `/banners/${experience_id}/analytics`,
              timestamp,
            };

          case isChecklistEvent:
            return {
              action,
              activity: 'checklist',
              displayName: selectAccountChecklist(state, checklist_id)
                ?.internalName,
              href: `/checklists/${checklist_id}/analytics`,
              timestamp,
            };

          case isNpsEvent:
            return {
              action,
              activity: 'nps',
              displayName: /appcues:nps_score/.test(name)
                ? nps_score
                : nps_feedback,
              href: `/nps`,
              timestamp,
            };

          case isGoalEvent:
            return {
              action,
              activity: 'goal',
              displayName: selectAccountGoal(state, goal_id)?.name,
              href: `/goals/${goal_id}`,
              timestamp,
            };

          case isBuilderEvent:
            return {
              action,
              activity: 'builder',
              displayName: name.replace(/appcues_custom:/, ''),
              href: `/insights/explorer/builder/${name.replace(
                /appcues_custom:/,
                ''
              )}`,
              timestamp,
            };

          default:
            return {
              action,
              activity: 'application',
              displayName: name.replace(/appcues_custom:/, ''),
              href: `/insights/explorer/application/${name}`,
              timestamp,
            };
        }
      }
    )
    .filter(({ displayName }) => Boolean(displayName));
};
