import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';
import { Screenshot } from 'next/components/Screenshot';
import { Shape as FlowShape } from 'next/entities/flows';
import { openFlow } from 'next/lib/builder';
import { FlowLabel, FlowStep, Carousel } from './styled';

const sortSteps = (a, b) => a.index - b.index;

const FlowStepsCarousel = ({
  showLabelIcon = false,
  previewUrl,
  flow,
  localeId,
}) => {
  const steps = Object.values(flow.steps) || [];

  if (steps.length === 0) {
    return null;
  }

  const flowSteps = steps
    .filter(({ stepType }) => stepType !== 'action')
    .flatMap(
      ({
        id: groupId,
        steps: childSteps,
        name,
        hotspots: hotspotSteps,
        index,
      }) =>
        Object.values(childSteps ?? hotspotSteps)
          .sort(sortSteps)
          .map(({ id: childId }) => ({
            groupId,
            childId,
            name,
            index,
          }))
    )
    .sort(sortSteps);

  return (
    <Carousel
      items={flowSteps.map((step, stepIndex) => {
        const title = step.name ?? `Step ${stepIndex + 1}`;

        const handleOpenInBuilder = () => {
          if (!previewUrl) return;

          openFlow({
            flow: step.groupId,
            step: step.childId,
            url: previewUrl,
          });
        };

        return {
          title: `${title} - ${step.id}`,
          element: (
            <FlowStep
              onClick={handleOpenInBuilder}
              role={previewUrl ? 'button' : undefined}
            >
              <Screenshot
                step={{
                  type: 'flows',
                  id: flow.id,
                  stepGroupId: step.groupId,
                  stepChildId: step.childId,
                }}
                locale={localeId}
              />
              <FlowLabel>
                {showLabelIcon && (
                  <Icon icon="thumbtack" data-testid="label-icon" />
                )}
                {title}
              </FlowLabel>
            </FlowStep>
          ),
        };
      })}
    />
  );
};

FlowStepsCarousel.propTypes = {
  showLabelIcon: PropTypes.bool,
  previewUrl: PropTypes.string,
  flow: FlowShape,
  localeId: PropTypes.string,
};

export default FlowStepsCarousel;
