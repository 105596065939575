import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Page, PageBody, Spinner } from '@studio/legacy-components';
import {
  ConditionsPropsShape,
  SelectOptionsShape,
  Shape,
  getMobileTargetingSections,
} from '@studio/conditions';

import {
  AudiencePanel,
  PrioritizationPanel,
  GoalPanel,
  ActionsPanel,
} from '@studio/settings-panels';
import { replace as replacePage } from 'next/entities/page';
import { drop as dropContentStatus } from 'next/entities/content-status';
import { update as updateRule, selectRule } from 'next/entities/rules';
import { selectAccount } from 'next/entities/account';
import { selectExperience } from 'next/entities/experiences';
import { selectUser } from 'next/entities/user';
import { ROLES, selectAccountUser } from 'next/entities/account-users';
import { readOne as readSuggestions } from 'next/entities/suggestions';
import { selectPublishedExperienceRules } from 'next/entities/published-rules';
import { ExperimentShape } from 'next/entities/experiment';
import {
  MOBILE_PUBLISHING,
  MOBILE_FLOWS,
  selectFeature,
  FLOW_PRIORITIZATION,
  GOAL_LOOK_BACK_WINDOW,
} from 'next/entities/features';
import { create as createSegment } from 'next/entities/segments';
import { GoalShape } from 'next/entities/goals';
import MobileHeader from 'next/components/MobileHeader';
import {
  selectSegmentTargetingOptions,
  selectGoalOptions,
  selectMobileFlowsOptions,
} from 'next/lib/selectors-options';
import { selectConditionsOptions } from 'next/lib/selector-conditions-options';
import { Loading } from './styled';
import MobileBanner from './MobileBanner';
import Trigger from './Trigger';
import Screen from './Screen';
import Details from './Details';
import SettingsHeaderActions from './SettingsHeaderActions';
import { Version } from './Version';
import FlowFrequency from './FlowFrequency';

export const SettingsPage = ({
  accountId,
  canPublish,
  conditionsOptions,
  email,
  experiment,
  fullName,
  hasFlowPrioritization,
  hasMobileFlows,
  id,
  loaded,
  mobileFlows,
  onLoad,
  onRuleChange,
  onSegmentCreate,
  onUnload,
  published,
  publishedRules,
  rule,
  segmentsOptions = [],
  state,
  goal,
  goalConversionWindowDays,
  goalOptions,
  mobileFlowOptions,
  onAttributeChange,
  hasLookBackWindow,
}) => {
  useEffect(() => {
    onLoad();
    return onUnload;
  }, [onLoad, onUnload]);

  const hasLiveExperiment = useMemo(() => {
    if (!experiment?.state || state !== 'DRAFT') return false;

    return ['LIVE', 'PAUSED'].includes(experiment.state.toUpperCase());
  }, [experiment?.state, state]);

  const hasLiveOrNotStartedExperiment = useMemo(() => {
    if (!experiment?.state || state !== 'DRAFT') return false;

    return ['NOT_STARTED', 'LIVE', 'PAUSED'].includes(
      experiment.state.toUpperCase()
    );
  }, [experiment?.state, state]);

  if (!loaded) {
    return (
      <Loading>
        <Spinner aria-label="Loading" />
      </Loading>
    );
  }

  const sections = getMobileTargetingSections(rule.conditions);

  // TODO: avoid passing the branches to onChange even though they hadn't changed
  const handleSectionChange = section => (newConditions, ruleChanges) => {
    return void onRuleChange({
      ...ruleChanges,
      conditions: {
        and: Object.values({
          ...sections,
          [section]: newConditions,
        }).filter(branch => branch && (branch.and || branch.or).length > 0),
      },
    });
  };

  const handlePriorityChange = sortPriority => {
    onRuleChange({ sortPriority });
  };

  return (
    <Page>
      <MobileHeader
        id={id}
        currentPage="settings"
        renderActions={({ updatedAt, state: experienceState }) => {
          return (
            <SettingsHeaderActions
              accountId={accountId}
              id={id}
              published={published}
              updatedAt={updatedAt}
              state={experienceState}
              hasActiveExperiment={hasLiveExperiment}
              experiment={experiment}
              canPublish={canPublish}
            />
          );
        }}
      />
      <PageBody>
        <MobileBanner id={id} hasActiveExperiment={hasLiveExperiment} />
        <Details id={id} />
        <Version
          conditions={sections.version}
          onChange={handleSectionChange('version')}
        />
        <AudiencePanel
          conditions={sections.audience}
          conditionsOptions={conditionsOptions}
          experienceName="Flow"
          hasMobileFlows={hasMobileFlows}
          onChange={handleSectionChange('audience')}
          onSegmentCreate={onSegmentCreate}
          segmentsOptions={segmentsOptions}
          onAttributeChange={onAttributeChange}
        />
        <Trigger
          conditions={sections.trigger}
          frequency={rule.frequency}
          onChange={handleSectionChange('trigger')}
        />
        <Screen
          conditions={sections.screen}
          onChange={handleSectionChange('screen')}
        />
        <PrioritizationPanel
          email={email}
          fullName={fullName}
          flows={mobileFlows}
          onChange={handlePriorityChange}
          publishedRules={publishedRules}
          rule={rule}
          showUpgradePanel={!hasFlowPrioritization}
        />

        <FlowFrequency
          onRuleChange={onRuleChange}
          throttleExempt={rule.throttleExempt}
        />
        <GoalPanel
          disableSetNoGoal={hasLiveOrNotStartedExperiment}
          onChange={values => {
            onRuleChange({
              goals: [values.goal],
              goalConversionWindowDays: values.goalConversionWindowDays,
            });
          }}
          goal={goal}
          conversionWindowDays={goalConversionWindowDays}
          options={goalOptions}
          hasLookBackWindow={hasLookBackWindow}
        />
        <ActionsPanel
          rule={rule}
          onSave={onRuleChange}
          options={mobileFlowOptions}
        />
      </PageBody>
    </Page>
  );
};

SettingsPage.propTypes = {
  accountId: PropTypes.string,
  canPublish: PropTypes.bool,
  conditionsOptions: ConditionsPropsShape,
  email: PropTypes.string,
  experiment: ExperimentShape,
  fullName: PropTypes.string,
  hasFlowPrioritization: PropTypes.bool,
  hasMobileFlows: PropTypes.bool,
  hasLookBackWindow: PropTypes.bool,
  id: PropTypes.string,
  loaded: PropTypes.bool,
  mobileFlows: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onLoad: PropTypes.func,
  onRuleChange: PropTypes.func,
  onSegmentCreate: PropTypes.func,
  onUnload: PropTypes.func,
  published: PropTypes.bool,
  publishedRules: PropTypes.objectOf(Shape),
  rule: Shape,
  segmentsOptions: SelectOptionsShape,
  state: PropTypes.string,
  goal: PropTypes.string,
  goalConversionWindowDays: PropTypes.number,
  goalOptions: PropTypes.arrayOf(GoalShape),
  mobileFlowOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onAttributeChange: PropTypes.func,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { experienceId },
    },
  }
) => {
  const { id: accountId } = selectAccount(state);
  const experience = selectExperience(state, experienceId);
  const rule = selectRule(state, experienceId);
  const user = selectUser(state);
  const { id: userId, email, fullname: fullName } = user;
  const accountUser = selectAccountUser(state, userId);
  const hasMobilePublishing = selectFeature(state, MOBILE_PUBLISHING);
  const canPublish =
    accountUser?.roleId !== ROLES.EDITOR && hasMobilePublishing;
  const mobileFlowOptions = selectMobileFlowsOptions(state);

  /*
     The prioritization panel should only show flows of a single type: mobile or mobile_embed.
     The flows shown depend only on the published rules that are passed to the PrioritizationPanel,
     so we first need to get a list of mobile flows of a type and then filter the published rules to
     only contain those for the correct flow type.
  */
  const experienceType = (experience?.type ?? '').toUpperCase();
  const mobileFlows = mobileFlowOptions
    .filter(({ type }) => type === experienceType)
    .reduce((acc, { label, value }) => {
      acc[value] = { name: label };
      return acc;
    }, {});

  const publishedExperienceRules = selectPublishedExperienceRules(state);
  const publishedRulesForType = Object.values(publishedExperienceRules).filter(
    ({ id }) => Boolean(mobileFlows[id])
  );

  return {
    accountId,
    canPublish,
    conditionsOptions: selectConditionsOptions(state),
    email,
    experiment: experience?.experiment,
    fullName,
    hasFlowPrioritization: selectFeature(state, FLOW_PRIORITIZATION),
    hasMobileFlows: selectFeature(state, MOBILE_FLOWS),
    hasLookBackWindow: selectFeature(state, GOAL_LOOK_BACK_WINDOW),
    id: experienceId,
    loaded: Boolean(experience && rule && accountUser),
    mobileFlows,
    published: experience?.published,
    mobileFlowOptions,
    publishedRules: publishedRulesForType,
    rule,
    segmentsOptions: selectSegmentTargetingOptions(state),
    state: experience?.state,
    goalOptions: selectGoalOptions(state) || [],
    goal: rule?.goals?.[0],
    goalConversionWindowDays: rule?.goalConversionWindowDays,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { experienceId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onUnload: () => dispatch(dropContentStatus(id)),
  onRuleChange: delta => dispatch(updateRule(id, delta)),
  onSegmentCreate: data => dispatch(createSegment(data)),
  onAttributeChange: (type, attributeName, term) =>
    dispatch(readSuggestions({ type, attributeName, term })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
