import styled from 'styled-components';
import { Section, Table } from '@appcues/sonar';

export const Wrapper = styled(Section)`
  width: 100%;
  min-height: 232px;
  display: flex;
  flex-direction: column;
  // We override the padding here to match the padding of the CPanel component. When this component
  // is built in studio5 we should use the default padding from the Section component for all sections.
  padding: var(--spacing-large);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Cell = styled(Table.Td)`
  min-width: 142px;
  max-width: 264px;
`;

export const CapitalizedCell = styled(Table.Td)`
  text-transform: capitalize;
  min-width: 142px;
  max-width: 264px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  padding: var(--spacing-large);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-large);
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: center;
`;

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--table-wrapper-min-height) - 38px);
  flex-direction: column;
  gap: var(--spacing-regular);
  margin-top: var(--spacing-regular);
`;
