import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { local } from 'next/lib/storage';
import { selectAccount } from 'next/entities/account';
import { RenderWithoutDomainPermission } from 'next/components/RenderWithoutDomainPermission';
import useVerifyExtension from 'next/hooks/use-verify-extension';
import { requestDomainPermission, setAccount } from 'next/lib/crx';
import { InstallChromeExtensionModal } from 'next/components/InstallChromeExtensionModal';
// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';
import OpenInBuilderModal from './OpenInBuilderModal';

const STORAGE_KEY = 'appcues:open-in-builder';

export const OpenInBuilderManager = ({
  onClose,
  onNavigate,
  type,
  visible,
  accountId,
  ...unhandledProps
}) => {
  const { hasCRXInstalled, hasPermission } = useVerifyExtension();

  const dismissed = !!local.get(`${STORAGE_KEY}:${type}`);

  const handleNavigation = useCallback(async () => {
    if (!hasPermission) {
      await requestDomainPermission();
    }

    const jwt = await authClient.getToken();
    setAccount({ accountId, jwt });

    onNavigate();
  }, [hasPermission, onNavigate, accountId]);

  useEffect(() => {
    if (dismissed && visible) {
      handleNavigation();
    }
  }, [dismissed, handleNavigation, visible]);

  const handleSave = checked => {
    local.set(`${STORAGE_KEY}:${type}`, checked);
    handleNavigation();
    onClose();
  };

  if (visible && !hasCRXInstalled) {
    return <InstallChromeExtensionModal onDismiss={onClose} />;
  }

  return (
    <RenderWithoutDomainPermission
      visible={!dismissed && visible}
      onNavigate={onNavigate}
      onClose={onClose}
    >
      <OpenInBuilderModal
        onClose={onClose}
        onSave={handleSave}
        type={type}
        visible={!dismissed && visible}
        {...unhandledProps}
      />
    </RenderWithoutDomainPermission>
  );
};

OpenInBuilderManager.propTypes = {
  onClose: PropTypes.func,
  onNavigate: PropTypes.func,
  type: PropTypes.oneOf(['flows', 'pins', 'mobile', 'banner', 'launchpad']),
  visible: PropTypes.bool,
  accountId: PropTypes.string,
};

const mapStateToProps = state => ({
  accountId: selectAccount(state)?.id,
});

export default connect(mapStateToProps)(OpenInBuilderManager);
