import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getQueryConditionsForSelectedEvents } from 'components/Insights/utils';
import QueryResults from 'components/Common/QueryResults';

import Table from './Table';

const TableWithAnalytics = ({
  source,
  startTime,
  endTime,
  events,
  segmentId,
  pageSize,
  searchTerm,
}) => {
  const [orderByColumn, setOrderByColumn] = useState('timestamp');
  const [orderByDirection, setOrderByDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(
    () => setCurrentPage(0),
    [
      startTime,
      endTime,
      segmentId,
      pageSize,
      orderByColumn,
      orderByDirection,
      searchTerm,
    ]
  );

  const handleSort = column => {
    if (column === orderByColumn) {
      setOrderByDirection(orderByDirection === 'asc' ? 'desc' : 'asc');
    }
    setOrderByColumn(column);
  };

  const conditions = getQueryConditionsForSelectedEvents(events);

  if (segmentId) {
    conditions.push(['user_segment_id', '==', segmentId]);
  }

  if (searchTerm) {
    conditions.push([
      'or',
      ...['name', 'user_id', 'a:_identity:_currentPageUrl'].map(column => [
        column,
        'ilike',
        `%${searchTerm}%`,
      ]),
    ]);
  }

  return (
    <QueryResults
      query={{
        conditions,
        metrics: ['events'],
        start_time: startTime,
        end_time: endTime,
        meta: {
          tags: {
            feature: 'Saved charts',
            page: 'View saved chart',
            component: 'EventsByUserTable',
            description: 'Events by user table result size',
          },
        },
      }}
    >
      {([{ events: totalResults }] = [{ events: 0 }]) => (
        <QueryResults
          query={{
            conditions,
            columns: [
              'user_id',
              'name',
              'flow_id',
              'goal_id',
              'checklist_id',
              'a:_identity:_currentPageUrl',
              'timestamp',
              'a:_identity',
            ],
            order_by: [[orderByColumn, orderByDirection]],
            limit: pageSize,
            offset: currentPage * pageSize,
            start_time: startTime,
            end_time: endTime,
            meta: {
              tags: {
                feature: 'Saved charts',
                page: 'View saved chart',
                component: 'EventsByUserTable',
                description: 'Events by user table result single results page',
              },
            },
          }}
        >
          {results => {
            const isEmpty = results && results.length === 0 && !searchTerm;

            return (
              <Table
                source={source}
                results={results}
                isEmpty={isEmpty}
                currentPage={currentPage}
                totalResults={totalResults}
                pageSize={pageSize}
                orderByColumn={orderByColumn}
                orderByDirection={orderByDirection}
                onSort={handleSort}
                onChangePage={setCurrentPage}
              />
            );
          }}
        </QueryResults>
      )}
    </QueryResults>
  );
};

TableWithAnalytics.propTypes = {
  source: PropTypes.string,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
  searchTerm: PropTypes.string,
};

export default TableWithAnalytics;
