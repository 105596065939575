import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  H1,
  Page,
  PageBody,
  PageActions,
  PageTitle,
} from '@studio/legacy-components';
import { Row, Tabs } from '@appcues/sonar';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { useLocation, Link } from 'react-router-dom';
import { Shape } from '@studio/conditions';
import { Shape as TagShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { Shape as SegmentShape } from 'next/entities/segments';

import { Shape as MobileFlowShape } from 'next/entities/experiences';
import { Shape as FlowShape } from 'next/entities/flows';
import { asRequest } from 'next/entities/requests';
import { AppShape } from 'next/entities/apps';
import { Loading } from 'next/components/Listing';

import { HelpBar } from 'next/components/HelpBar';
import CreateExperience from './CreateExperience';
import FlowsMoreMenu from './FlowsMoreMenu';
import PageList from './PageList';
import {
  TabSection,
  LoadingTabSpinner,
  TabsListHeader,
  TabsTriggerHeader,
  PageHeaderWithTabs,
  FullPageTabRoot,
  FullPageTabContent,
} from './styled';

const FLOWS_PERFORMANCE_TAB = 'FLOWS_PERFORMANCE_TAB';
const FLOWS_LIST_TAB = 'FLOWS_LIST_TAB';

const useDefaultTabValue = () => {
  const { pathname } = useLocation();

  if (pathname.replace(/\/$/, '') === '/flows') {
    return FLOWS_LIST_TAB;
  }
  return FLOWS_PERFORMANCE_TAB;
};

/**
 * FIXME: There is a brief FOUC on initial load where it shows the empty state
 *        before loading. This appears to be due to how we have the request
 *        selector set up where it returns `loading: undefined`.
 */

const FlowsListingPage = ({
  loading,
  creators = {},
  domainAssociations = {},
  flows = {},
  mobileFlows = {},
  onLoad,
  segments = {},
  tags = {},
  apps = {},
  // eslint-disable-next-line no-unused-vars
  rules = {},
}) => {
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const defaultTabValue = useDefaultTabValue();
  return (
    <Page>
      <PageHeaderWithTabs>
        <PageTitle>
          <H1>Flows</H1>
        </PageTitle>

        <PageActions>
          <FlowsMoreMenu />
          <CreateExperience />
        </PageActions>
      </PageHeaderWithTabs>
      <FullPageTabRoot
        align="left"
        defaultValue={defaultTabValue}
        size="regular"
      >
        <TabsListHeader aria-label="tabs default">
          <TabsTriggerHeader value={FLOWS_LIST_TAB} icon={faList}>
            All Flows
          </TabsTriggerHeader>
          <TabsTriggerHeader
            value={FLOWS_PERFORMANCE_TAB}
            icon={faChartLine}
            forwardedAs={Link}
            to="/flows/performance/"
            asChild
          >
            Performance Metrics
          </TabsTriggerHeader>
        </TabsListHeader>
        <FullPageTabContent
          value={FLOWS_LIST_TAB}
          vAlign={loading ? 'center' : 'start'}
        >
          <TabSection>
            <PageBody>
              <HelpBar section="flows" />
              {loading && <Loading />}
              {!loading && (
                <PageList
                  creators={creators.data}
                  domainAssociations={domainAssociations.data}
                  flows={{ ...flows.data, ...mobileFlows.data }}
                  segments={segments.data}
                  tags={tags.data}
                  apps={apps.data}
                />
              )}
            </PageBody>
          </TabSection>
        </FullPageTabContent>
        <Tabs.Content value={FLOWS_PERFORMANCE_TAB}>
          <Row xAlign="space-around" yAlign="center">
            <LoadingTabSpinner />
          </Row>
        </Tabs.Content>
      </FullPageTabRoot>
    </Page>
  );
};

FlowsListingPage.propTypes = {
  loading: PropTypes.bool,
  domainAssociations: asRequest(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
  ),
  flows: asRequest(PropTypes.objectOf(FlowShape)),
  mobileFlows: asRequest(PropTypes.objectOf(MobileFlowShape)),
  tags: asRequest(PropTypes.objectOf(TagShape)),
  apps: asRequest(PropTypes.objectOf(AppShape)),
  segments: asRequest(PropTypes.objectOf(SegmentShape)),
  creators: asRequest(PropTypes.objectOf(CreatorShape)),
  rules: asRequest(PropTypes.objectOf(Shape)),
  onLoad: PropTypes.func,
};

export default FlowsListingPage;
