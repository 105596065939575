import { connect } from 'react-redux';
import { CONFIGURATION_PAGE } from 'next/entities/features';
import { selectAccountFeature } from 'reducers/account/features';
import Panel from 'components/Insights/Charts/View/EventsByUserTable/Panel';

const mapStateToProps = state => {
  return {
    hasConfigurationPage: selectAccountFeature(state, CONFIGURATION_PAGE),
  };
};

export default connect(mapStateToProps)(Panel);
