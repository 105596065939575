export const FLOWS_FIREBASE_KEY = 'journeys';

export const CREATE_FLOW_MODAL = 'CREATE_FLOW_MODAL';
export const CREATE_PRODUCT_LED_FLOW_MODAL = 'CREATE_PRODUCT_LED_FLOW_MODAL';
export const CREATE_SEGMENT_MODAL = 'CREATE_SEGMENT_MODAL';
export const UPDATE_SEGMENT_MODAL = 'UPDATE_SEGMENT_MODAL';
export const DELETE_SEGMENT_MODAL = 'DELETE_SEGMENT_MODAL';
export const DEFINE_GOAL_MODAL = 'DEFINE_GOAL_MODAL';
export const PUBLISHING_MODAL = 'PUBLISHING_MODAL';
export const VERSION_NOT_FOUND_MODAL = 'VERSION_NOT_FOUND_MODAL';
export const GOAL_LIMIT_MODAL = 'GOAL_LIMIT_MODAL';
export const DELETE_GOAL_MODAL = 'DELETE_GOAL_MODAL';
export const USER_PROFILE_MODAL = 'USER_PROFILE_MODAL';
export const CREATE_SATISFACTION_SURVEY_MODAL =
  'CREATE_SATISFACTION_SURVEY_MODAL';
export const NPS_TRIAL_EXPIRATION_MODAL = 'NPS_TRIAL_EXPIRATION_MODAL';
export const NPS_FLOW_UPSELL_MODAL = 'NPS_FLOW_UPSELL_MODAL';
export const EXPORT_MODAL = 'EXPORT_MODAL';
export const CONFIRM_REMOVE_USER_FROM_TEAM_MODAL =
  'CONFIRM_REMOVE_USER_FROM_TEAM_MODAL';
export const FLOW_PUBLISHING_MODAL = 'FLOW_PUBLISHING_MODAL';
export const DELETE_CHECKLIST_MODAL = 'DELETE_CHECKLIST_MODAL';
export const EXPORT_NPS_SURVEY_CSV_MODAL = 'EXPORT_NPS_SURVEY_CSV_MODAL';
export const EDIT_CHECKLIST_ITEM_MODAL = 'EDIT_CHECKLIST_ITEM_MODAL';
export const TEST_FLOW_MODAL = 'TEST_FLOW_MODAL';
export const PERMALINK_MODAL = 'PERMALINK_MODAL';
export const INSTALL_SEGMENT_MODAL = 'INSTALL_SEGMENT_MODAL';
export const CONFIRM_RESET_PASSWORD_MODAL = 'CONFIRM_RESET_PASSWORD_MODAL';
export const CONFIRM_REMOVE_INTEGRATION_MODAL =
  'CONFIRM_REMOVE_INTEGRATION_MODAL';
export const CONFIRM_ACTIVATE_INTEGRATION_MODAL =
  'CONFIRM_ACTIVATE_INTEGRATION_MODAL';
export const FIRST_CANCELLATION_MODAL = 'FIRST_CANCELLATION_MODAL';
export const SECOND_CANCELLATION_MODAL = 'SECOND_CANCELLATION_MODAL';
export const THIRD_CANCELLATION_MODAL = 'THIRD_CANCELLATION_MODAL';
export const INSTALL_TO_INTEGRATE_MODAL = 'INSTALL_TO_INTEGRATE_MODAL';
export const SEGMENT_BLOCKED_MODAL = 'SEGMENT_BLOCKED_MODAL';
export const THEME_FLOWS_MODAL = 'THEME_FLOWS_MODAL';
export const DELETE_THEME_MODAL = 'DELETE_THEME_MODAL';
export const ZAPIER_INTEGRATION_MODAL = 'ZAPIER_INTEGRATION_MODAL';
export const SALESFORCE_BETA_INTEGRATION_MODAL =
  'SALESFORCE_BETA_INTEGRATION_MODAL';
export const HUBSPOT_TWO_WAY_MODAL = 'HUBSPOT_TWO_WAY_MODAL';
export const ZENDESK_SUPPORT_TWO_WAY_MODAL = 'ZENDESK_SUPPORT_TWO_WAY_MODAL';
export const SLACK_TWO_WAY_MODAL = 'SLACK_TWO_WAY_MODAL';
export const TRAY_INTEGRATION_MODAL = 'TRAY_INTEGRATION_MODAL';
