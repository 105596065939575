import styled from 'styled-components';
import {
  Icon,
  PanelTitle,
  StatusLabel,
  Table,
} from '@studio/legacy-components';
import { Input } from '@appcues/sonar';

export const TooltipIcon = styled(Icon).attrs({
  icon: 'info-circle',
})`
  color: var(--text-secondary);
  vertical-align: middle;
  font-size: var(--medium-large);
`;

export const URLMark = styled.mark`
  background-color: rgba(var(--blues-cues-rgb), 0.2);
  color: var(--blues-cues);
`;

export const UserListTitle = styled(PanelTitle)`
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

export const TooltipContentWrapper = styled.div`
  width: 300px;
  white-space: initial;
`;

export const TruncateText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Dots = styled(Icon)`
  color: var(--dark-resting);
`;

export const UsersTable = styled(Table)`
  th:last-of-type {
    width: 5%;
  }
`;

export const UpgradeBadge = styled(StatusLabel)`
  font-weight: var(--normal);
  word-break: keep-all;
  user-select: none;
  width: 105px;
`;

export const SearchInput = styled(Input)`
  min-width: 240px;
`;
