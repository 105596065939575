import React from 'react';
import { components } from 'react-select';
import { OptionLabel, OptionWrapper } from '@studio/legacy-components';
import { AttributeOption } from './styled';

export default function IdentifierOption({
  data,
  innerProps,
  isSelected,
  isDisabled,
  ...props
}) {
  return (
    <components.Option
      {...props}
      data={data}
      innerProps={{
        ...innerProps,
        role: 'option',
      }}
      isSelected={isSelected}
    >
      <OptionWrapper isSelected={isSelected} $isDisabled={isDisabled}>
        <OptionLabel>
          <AttributeOption>{data.label}</AttributeOption>
        </OptionLabel>
      </OptionWrapper>
    </components.Option>
  );
}
