import styled from 'styled-components';
import { H5, Text, Icon } from '@studio/legacy-components';

export const MetricCardContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1;
  padding: 24px;

  ${H5} {
    color: var(--sharkbait-ooh-la-la);
    font-weight: var(--bold);
  }

  ${Text} {
    color: var(--pleather);
  }

  ${Icon} {
    color: var(--icon);
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardCaption = styled.div`
  min-height: 20px;
`;
